import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, Training } from '../components'
import { Article, ArticleHorizontal } from '../components/section'

import backgroundImage from '../images/haeussler-job-02.webp'
import background from '../images/haeussler-job-04.webp'
import thumb from '../images/mehrLaecheln.png'

const JobWork = () => {
  const {t} = useTranslation()

  return (
    <main>
      <Article
        id={'work'}
        headline={t(`haedline-work`)}
        subline={t(`sub-headline-work`)}
        content={t(`content-work`)}
        image={backgroundImage}
      />
      <ArticleHorizontal
        id={'workplace'}
        headline={t(`haedline-workplace`)}
        subline={t(`sub-headline-workplace`)}
        content={t(`content-workplace`)}
        image={background}
        card={thumb}
      />
      <Training />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default JobWork;