import image1 from './slide_01.webp'
import image2 from './slide_02.webp'
import image3 from './slide_03.webp'
import image4 from './slide_04.webp'
import image5 from './slide_05.webp'
import image6 from './slide_06.webp'
import image7 from './slide_07.webp'
import image8 from './slide_08.webp'
import image9 from './slide_09.webp'
import image0 from './slide_10.webp'

const slideData = [
  {
    index: 0,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Shop now',
    src: image1
  },
  {
    index: 1,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Book travel',
    src: image2
  },
  {
    index: 2,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Listen',
    src: image3
  },
  {
    index: 3,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image4
  },
  {
    index: 4,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image5
  },
  {
    index: 5,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image6
  },
  {
    index: 6,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image7
  },
  {
    index: 7,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image8
  },
  {
    index: 8,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image9
  },
  {
    index: 9,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image0
  }
]

export default slideData