import React, { useState} from 'react'
import { Grid, Image, Container } from 'semantic-ui-react'

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }

const JobImage = (props) => {
  const [ hover, setHover ] = useState(false)

  const style = {
    container: {
      position: 'relative',
      opacity: hover ? 0.6 : 1,
      cursor: hover ? 'pointer' : 'auto',
      transition: 'all 1s'
    },
    content : {
      position: 'absolute',
      zIndex: 1,
      color: 'rgba(255,255,255,.9)',
      bottom: 10,
      left: '50%',
      transform: 'translate(-50%, 0px)',
      borderTop: '2px solid rgba(255,255,255,.9)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    },
    header : {
      fontSize: '2em',
      textTransform: 'uppercase',
      position: 'absolute',
      zIndex: 1,
      color: 'rgba(255,255,255,.9)',
      background: 'rgba( 0, 0, 0, 0.7)',
      padding: 15,
      bottom: '15%',
      left: '50%',
      transform: 'translate(-50%, 0px)',
      maxWidth: 300,
      lineHeight: 1.1
    }
  }

  return(
    <Grid.Column>
      <div style={style.container} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>props.onItemClick(props.link)}>
        <Image src={props.src} size='medium' style={{margin: 'auto'}} />
        <div style={style.header}>
          {props.content}
        </div>
        <div style={style.content}>
          zu den Angeboten
        </div>
      </div>
    </Grid.Column>
  )
}

const JobOffers = (props) => {
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg|webp)$/));
  if(!props.offers)
    return null

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container fluid key={props.id} id={`#${props.id}`} >
        <Grid stackable container columns={3}>
          {props.offers.map((element, index) => {
            let img = element.image
            return(<JobImage key={`instagram-image-#${index}`} src={images[img]} link={element.link} header={element.headline} content={element.job} onItemClick={props.onItemClick} />)
          })}
        </Grid>
      </Container>
    </section>
  );
}

export default JobOffers;