import React, { useState, useEffect } from "react"
import GoogleMapReact from "google-map-react"
import Marker from './marker'

const Map = (props) => {
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState();

  useEffect(() => {
    if(props.selected) {
      if(props.selected.geometry) {
        setZoom(15)
        setCenter({lat: props.selected.geometry.location.lat, lng: props.selected.geometry.location.lng})
      }
    }
    else {
      setZoom(11)
      setCenter({lat: 48.1613017683767, lng: 10.11359839872111})
    }
  },[props])

  const renderMarker = (el) => {
    if(props.selected)
      if(props.selected.geometry)
        return(<Marker           
          lat={props.selected.geometry.location.lat}
          lng={props.selected.geometry.location.lng}
          name={props.selected.name}
          id={props.selected.id}/>)

    var items = []
    el.forEach(element => {
      if(element.geometry)
        items.push(
          <Marker
            key={`map-marker-#${element.id}`}
            lat={element.geometry.location.lat}
            lng={element.geometry.location.lng}
            name={element.name}
            id={element.id}
            onSelect={props.onSelect}
          />
        )
    });
    return items
  }

  return (
    <div style={!props.styles ? { minHeight: '100vh', height: "100%", width: "100%" } : props.styles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAveFSPWTXijCY1hPFvMNyp3r-4EyptMbY" }}
        center={center}
        zoom={zoom}
      >
        {props.stores && (
          renderMarker(props.stores)
        )}
      </GoogleMapReact>
    </div>
  );
};

export default Map;