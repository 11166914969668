import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Container, Icon } from 'semantic-ui-react'
import StorServices from './storeservices'
import { GoogleMapsButton, PhoneButton, OpenButton, MailButton, DrawButton } from '../button'
import { OpeningHours } from '../'

const LocationDetails = (props) => {
  const {t} = useTranslation()

  return(
    <Container style={{padding: '30px'}}>
      <Header as='h2' onClick={props.onClose} >
        <Icon.Group>
          <Icon name='close' />
        </Icon.Group>
        {t(`button-close`)}
      </Header>
      <Header className={'headline-regular'} as='h1'>{props.name}</Header>
      {props.openNow && <OpenButton content={t(`button-nowopen`)} />}
      {props.opening && <Header className={'headline-bag'} as='h3'>{t(`headline-opening`)}</Header>}
      {props.opening && props.opening.map((el, index) => (
          <OpeningHours 
          open={el.open}
          close={el.close}
          key={t(`open-hours-item#${index}`)}
        />
      ))}
      <Header className={'headline-bag'} as='h3'>{t(`headline-contact`)}</Header>
      <div style={{display: 'inline-grid'}}>
        <GoogleMapsButton
          postalCode={props.postalCode}
          location={props.location}
          streetAddress={props.streetAddress}
          mapsLink={props.mapsLink}
        />
        {props.phone && <PhoneButton
          phone={props.phone}
        />}
        {props.mail && <MailButton
          mail={props.mail}
        />}
      </div>
      {props.services && (
        <>
          <Header className={'headline-bag'} as='h3'>{t(`headline-serices`)}</Header>
          <div style={{display: 'inline-grid'}}>
            {props.services.sort(function (a, b) {
                if(a === b) return 0;
                if (a < b)
                    return -1;
                if (a > b)
                    return 1;
                return 0;
              }).map((el) => (
              <StorServices 
                type={el}
                name={t(`service-content-${el}`)}
                key={t(`locations-stores-item#${el}`)}
              />
            ))}
          </div>
        </>
      )}
      <div>
        <DrawButton
          name={t(`button-setmystore`)}
          onClick={()=>props.onSelect(props.id)}
        />
      </div>
    </Container>
  );
}

export default LocationDetails;