import React from 'react'
import './buttons.scss'

const OpenButton = (props) => {

  return(
    <span 
      className="btn-styled icon-open " 
    >
			{props.content}
    </span>
  );
}

export default OpenButton;