import React from 'react'
import { Image } from 'semantic-ui-react'

import wifi_icon from './images/wifi.svg'
import open_icon from './images/open.svg'
import breakfast_icon from './images/breakfast.svg'
import accessible_icon from './images/rollstuhl.svg'
import cafe_icon from './images/cafe.svg'
import drive_icon from './images/drive-thru.svg'

const StorServices = (props) => {
  var image
  switch (props.type) {
    case 'breakfast':
      image = breakfast_icon
      break;
    case 'sunday':
      image = open_icon
      break;
    case 'wifi':
      image = wifi_icon
      break;
    case 'accessible':
      image = accessible_icon
      break;
    case 'cafe':
      image = cafe_icon
      break;
    case 'drive':
      image = drive_icon
      break;
    default:
      break;
  }

  if(!image)
    return null

  return(
    <div style={{ fontSize: "15px", padding: "10px" }}>
      <Image src={image} avatar />
      <span>{props.name}</span>
    </div>
  )
}

export default StorServices;