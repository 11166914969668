import React from 'react'
import { Image } from 'semantic-ui-react'

const ImageFull = (props) => {

  const styles = {
    image:{
      maxHeight: '100vh',
      objectFit: 'cover',
      objectPosition: '50% 10%'
    }
  }
  return(
    <section key={props.id} id={`#${props.id}`} style={props.style ? props.style : null} >
      <Image src={props.image} fluid style={styles.image}/>
    </section>
  );
} 

export default ImageFull