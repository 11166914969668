import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchStores, setMyStore } from "../../redux/actions/";
import { Grid, Container } from 'semantic-ui-react'
import { Map } from '../'
import LocationSearch from './locationsearch'
import LocationDetails from './locationdetails'
import { useModalWithData } from '../../hooks/useModal';
import { useNavigate } from 'react-router-dom';

const Locations = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef()
  const stores = useSelector((state) => state.stores)
  const [ attributes, setAttributes ] = useState([])
  const [ search, setSearch ] = useState()
  const [ modalOpen, setModalOpen, selected, setSelected, setModalState ] = useModalWithData(false)

  useEffect(() => {
      dispatch(fetchStores())
  },[])

  useEffect(() => {
    dispatch(fetchStores(true, search, attributes))
  },[search, attributes])

  const handleOnSelectMyStore = (id) => {
    dispatch(setMyStore(id))
    navigate('/mystore#places')
  }

  const handleOnLocationSelect = (id) => {
    var store = stores.filter((object) => { return object.id === id })
    setSelected(store[0])
    setModalOpen(true)
    ref.current.scrollIntoView(true)
  }

  const handleOnLocationClose = () => {
    setSelected(null)
    setModalOpen(false)
  }

  const handleOnAttributeChange = (e) => {
    var array = []
    array = array.concat(attributes)
    const index = array.indexOf(e);
    if(index > -1 )
      array.splice(index, 1)
    else
      array.push(e)

    setAttributes(array)
  }

  const handleOnSearchChange = (e) => {
    const { id, value } = e.target
    setSearch(value)
  }

  return(
    <Container fluid key={props.id} id={`#${props.id}`} >
      <Grid columns='equal'>
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <div ref={ref} style={{visibility:'hidden'}} />
          { modalOpen ? 
            <LocationDetails 
              show={modalOpen}
              id={selected.id}
              name={selected.name}
              postalCode={selected.postal_code}
              location={selected.location}
              streetAddress={selected.street_address}
              phone={selected.phone}
              mail={selected.mail}
              mapsLink={selected.maps_link}
              services={selected.attributes}
              openNow={selected.opening_hours && selected.opening_hours.open_now}
              opening={selected.opening_hours && selected.opening_hours.periods}
              onClose={handleOnLocationClose}
              onSelect={handleOnSelectMyStore}
            />
          :
            <LocationSearch
              stores={stores}
              onSelectLocation={handleOnLocationSelect}
              onAttributeClick={handleOnAttributeChange}
              onInputChange={handleOnSearchChange}
            />
          }
        </Grid.Column>
        <Grid.Column tablet={10} computer={10} only='tablet computer'>
          <Map
            stores={stores}
            selected={selected}
            onSelect={handleOnLocationSelect}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default Locations