import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { Loader } from 'semantic-ui-react'
import { Instagram, MyStore } from '../components'

import { fetchLocation } from '../utils/requestHelper'

const MainStore = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const params = useParams();
  const myStore = useSelector((state) => state.myStore)
  const images = require.context('../images', true);
  const [ selectedStore, setSelectedStore ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    const { storeId } = params
    async function fetchStoreData(storeId) {
      setLoading(true)
      let response = await fetchLocation(storeId)
      if(response.success === true) {
        setSelectedStore(response.data)
      }
      setLoading(false)
    }
    if(storeId) {
      fetchStoreData(storeId)
    }
    else {
      if(myStore)
        fetchStoreData(myStore)
      else
        navigate(`/places#places`)
    }
  },[myStore, params.storeId])

  const getImage = () => {
    try {
      return images(`./image-location-${myStore}.jpg`)
    }
    catch {
      return images(`./image-location-default.jpg`)
    }
  }

  let imgsrc = getImage()
  let styler = {
      backgroundImage: `url(${imgsrc})`,
      backgroundPosition: '50% 50%'
  }

  return (
    <main id={'#places'}>
      <div style={{margin: '50px'}}><Loader active={loading} inline='centered'  size='large'>{t(`content-loading`)}</Loader></div>
      {selectedStore && 
      <MyStore
        id={'myStore'}
        store={selectedStore}
        name={selectedStore.name}
        postalCode={selectedStore.postal_code} 
        location={selectedStore.location} 
        streetAddress={selectedStore.street_address} 
        services={selectedStore.attributes}
        phone={selectedStore.phone}
        mail={selectedStore.mail} 
        style={styler}
        mapsLink={selectedStore.maps_link}
        opening={selectedStore.opening_hours && selectedStore.opening_hours.periods}
        openNow={selectedStore.opening_hours && selectedStore.opening_hours.open_now}
      />}
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default MainStore;