import React from 'react'
import './buttons.scss'

const GoogleMapsButton = (props) => {

  return(
    <a 
      href={props.mapsLink} 
      target="blank" 
      className="btn-styled-icon spanish-white icon-googlemap" 
    >
			{`${props.streetAddress},`}<br />{`${props.postalCode} ${props.location}`}
    </a>
  );
}

export default GoogleMapsButton;