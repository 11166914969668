import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Container, Header, Grid } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { storeStructuredData } from "./structured-data"

import wifi_icon from './images/wifi.svg'
import open_icon from './images/open.svg'
import breakfast_icon from './images/breakfast.svg'
import accessible_icon from './images/rollstuhl.svg'
import cafe_icon from './images/cafe.svg'
import drive_icon from './images/drive-thru.svg'

import { OpeningHours } from '../'

import { GoogleMapsButton, PhoneButton, OpenButton, MailButton } from '../button'
import { Slider, Map } from '../'

import slide_01 from '../../images/slider/Backhaus-Häussler-27.webp'
import slide_02 from '../../images/slider/Backhaus-Häussler-28.webp'
import slide_03 from '../../images/slider/Backhaus-Häussler-33.webp'
import slide_04 from '../../images/slider/Backhaus-Häussler-34.webp'
import slide_05 from '../../images/slider/Backhaus-Häussler-57.webp'
import slide_06 from '../../images/slider/Backhaus-Häussler-49.webp'

const MyStoreServices = (props) => {
  var image
  switch (props.type) {
    case 'breakfast':
      image = breakfast_icon
      break;
    case 'sunday':
      image = open_icon
      break;
    case 'wifi':
      image = wifi_icon
      break;
    case 'accessible':
      image = accessible_icon
      break;
    case 'cafe':
      image = cafe_icon
      break;
    case 'drive':
      image = drive_icon
      break;
    default:
      break;
  }

  if(!image)
    return null

  return(
    <div style={{ fontSize: "15px", margin: "10px", display: "flex" }}>
      <Image src={image} avatar />
      <span>{props.name}</span>
    </div>
  )
}

const MyStore = (props) => {
  const {t} = useTranslation()

  const slideData = [
    {
      index: 0,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Shop now',
      src: slide_01
    },
    {
      index: 1,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Book travel',
      src: slide_02
    },
    {
      index: 2,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Listen',
      src: slide_03
    },
    {
      index: 3,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_04
    },
    {
      index: 4,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_05
    },
    {
      index: 5,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_06
    }
  ]

  return(<>
    <section key={props.id} id={`#${props.id}`}>
      <Helmet>
        <script type="application/ld+json">
          {storeStructuredData({
            id: props.store.id,
            name: props.name,
            postalCode: props.postalCode,
            streetAddress: props.streetAddress,
            location: props.location,
            latitude: props.store.geometry.location.lat,
            longitude: props.store.geometry.location.lng,
            phone: props.phone,
          })}
        </script>
      </Helmet>
      <Container>
        <article className='article horizontal img-first'>
            <div className='article__txt' >
              <Header className={'headline-regular'} as='h1'>{props.name}</Header>
              {props.openNow && <OpenButton content={t(`button-nowopen`)} />}
              {props.opening && <Header className={'headline-bag'} as='h3'>{t(`headline-opening`)}</Header>}
              {props.opening && props.opening.map((el, index) => (
                  <OpeningHours 
                  open={el.open}
                  close={el.close}
                  key={t(`open-hours-item#${index}`)}
                />
              ))}
              <Header className={'headline-bag'} as='h3'>{t(`headline-contact`)}</Header>
              <div style={{display: 'inline-grid'}}>
                <GoogleMapsButton
                  postalCode={props.postalCode}
                  location={props.location}
                  streetAddress={props.streetAddress}
                  mapsLink={props.mapsLink}
                />
                {props.phone && <PhoneButton
                  phone={props.phone}
                />}
                {props.mail && <MailButton
                  mail={props.mail}
                />}
              </div>
              {props.services && (
                <>
                  <Header className={'headline-bag'} as='h3'>{t(`headline-serices`)}</Header>
                  {props.services.sort(function (a, b) {
                      if(a === b) return 0;
                      if (a < b)
                          return -1;
                      if (a > b)
                          return 1;
                      return 0;
                    }).map((el) => (
                    <MyStoreServices 
                      type={el}
                      name={t(`service-content-${el}`)}
                      key={t(`locations-stores-item#${el}`)}
                    />
                  ))}
                </>
              )}
            </div>
            <div className='article__img' >
              <div className="img" style={props.style} ></div>
            </div>
        </article>
      </Container>
    </section>
    <section>
      <Container>
        <Grid>
          <Grid.Column>
            <Map
              key={`main-store-map-${props.store.id}`}
              styles={{ minHeight: '60vh', height: "100%", width: "100%" }}
              stores={[props.store]}
              selected={props.store}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </section>
    <section key={`slider-${props.id}`} id={`#slider-${props.id}`}>
      <Container>
        <Slider auto heading={t(`headline-slider-${props.name}`)} slides={slideData}/>
      </Container>
    </section></>
  );
}

export default MyStore;