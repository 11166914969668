import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Instagram, Home, Accordion, SnackCard } from '../components'
import { Header, Container, Image } from 'semantic-ui-react'
import { ImageFull } from '../components/section'
import {Helmet} from "react-helmet"
import { logoStructuredData, faqStructuredData } from './structured-data'
import config from '../config/main.json'

import image1 from '../images/sh7ua94haxybhylkd20211226202338.webp'
import image2 from '../images/IMG_4453.webp'
import image3 from '../images/haeussler-job-02.webp'
import image4 from '../images/pfeil.webp'


const Main = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const panels = [
    {
      label: t('panel-faq-question_1'),
      content:  t('panel-faq-answer_1'),
    },
    {
      label: t('panel-faq-question_2'),
      content: t('panel-faq-answer_2'),
    },	
    {
      label: t('panel-faq-question_3'),
      content: t('panel-faq-answer_3'),
    },
    {
      label: t('panel-faq-question_4'),
      content: t('panel-faq-answer_4'),
    },
    {
      label: t('panel-faq-question_5'),
      content: t('panel-faq-answer_5'),
    },
  ]

  const style = {
    line: {
      fontFamily: "Avenir Next Codensed Ultralight",
    },
    subSpan : {
      cursor: 'pointer',
      background: '#c39d63',
      padding: '3vw',
      borderRadius: 25,
      fontSize: '3vw',
      whiteSpace: 'nowrap',
      color: 'rgba(255,255,255,.9)',
      textTransform: 'uppercase',
      boxShadow: '10px 20px 15px silver',
      position: 'relative',
      animation: `pulsing 2.4s infinite`,
      '&:before': {
        content: '',
        boxShadow: '0 0 0 rgba(204, 169, 44, 0.4)',
        borderRadius: 25,
        width: 85,
        height: 85,
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto'
      }
    },
    subLine: {
      fontFamily: 'Alfa Slab One',
      fontSize: '8vw',
      fontWeight: '300',
      lineHeight: '1',
      color: '#000'
    },
    subText: {
      transform: 'rotate(-9deg)',
      color: '#000',
      fontSize: '3vw',
      textAlign: 'right'
    },
    subTextSmall: {
      transform: 'rotate(-9deg)',
      color: '#000',
      fontSize: '2vw',
      textAlign: 'right'
    },
    subContainer: {
      transform: 'rotate(-9deg)',
    },
    container: {
      textAlign: 'center'
    }
  }

  return (
    <main>
      <Helmet>
        <script type="application/ld+json">
          {logoStructuredData({
            url: config.company,
            logo: "https://bhmm.de/ios/256.png",
          })}
        </script>
        <script type="application/ld+json">
          {faqStructuredData()}
        </script>
      </Helmet>
      <Home id={'main'}/>
      <section key={'props.id'} id={`#breze`} style={{marginBottom: -190}}>
        <Container fluid >
          <article className='article horizontal'>
            <div className='article__img' style={{marginTop: 180}} >
              <Image src={image3} />
            </div>
            <div className='article__img absolute' style={{width: '70%', right: 0, alignSelf: 'start'}} >
              <div style={style.container} className="container">
                <div style={style.subContainer}>
                  <h2 style={style.subLine}>{t('content-brezel_01')} <span style={style.line}>{t('content-brezel_02')}</span> {t('content-brezel_03')}</h2>
                </div>
                <div>
                  <p style={style.subText}>{t('content-brezel_04')}</p>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-end', padding: 30, justifyContent: 'end'}}>
                  <div style={{width: '50%'}}>
                    <Image style={{height: 'auto'}} src={image4} />
                  </div>
                  <div onClick={()=>navigate('/jobs')}>
                    <span style={style.subSpan}>{t('content-brezel_05')}</span>
                  </div>
                </div>
                <div style={{paddingTop: '20%'}}>
                  <p style={style.subTextSmall}>{t('content-brezel_06')}</p>
                </div>
              </div>
            </div>
          </article>
        </Container>
      </section>
      <ImageFull id={'fullimage01'} image={image2} style={{paddingBottom: 0}} />
      <SnackCard />
      <Header className={'headline-regular'}  textAlign='center' as='h1'>{t(`headline-faq`)}</Header>
      <Container>
        <Accordion panels={panels} />
      </Container>
      <ImageFull id={'fullimage02'} image={image1} />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Main;