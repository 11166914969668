import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Grid, Header, Form, Message } from 'semantic-ui-react'
import { InputField } from '..'
import { DrawButton } from '../button'

const SearchOrderForm = (props) => {
  const {t} = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.handleOnSubmit()
    }
  }

  return(
    <section key={`#order-data`} id={`#order-data`}>
      <Container>
        <Grid divided stackable columns={2}>
          <Grid.Column>
            <Header className={'headline-regular'} as='h1'>{t(`haedline-orders`)}</Header>
          </Grid.Column>
          <Grid.Column textAlign='center' verticalAlign='middle'>
            <Header style={{marginBottom: 60}} as='h2'>{t(`sub-haedline-orders`)}</Header>
            <div style={{ width: '80%', margin: 'auto'}}>
              <Form>
                <Form.Group>
                  <InputField 
                    value={props.orderId}
                    valueError={props.orderIdError}
                    handleOnChange={props.handleOnChange}
                    id="orderId"
                    type="text"
                    label={t('input-label-order_number')}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <InputField 
                    value={props.mail}
                    valueError={props.mailError}
                    handleOnChange={props.handleOnChange}
                    onKeyDown={handleKeyDown}
                    id="mail"
                    type="mail"
                    label={t('input-label-mail')}
                    required
                  />
                </Form.Group>
                {props.error === true &&
                <Message negative>
                  <Message.Header>{t('error-headline-search_order')}</Message.Header>
                  <p>{t('error-content-search_order')}</p>
                </Message>}
                <Form.Group>
                  <DrawButton
                    name={t(`button-search`)}
                    onClick={props.handleOnSubmit}
                  />
                </Form.Group>
              </Form>
              <p>{t('content-orders')}</p>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
}

export default SearchOrderForm;