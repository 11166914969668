import React from 'react'
import './marker.scss'

const Marker = (props) => {
    const { name, id } = props
    return (
      <div>
        <div 
          className='maps-pin'
          style={{ cursor: 'pointer'}}
          title={name}
          onClick={()=>props.onSelect(id)}
        />
        <div className='maps-pulse'></div>
      </div>

    )
  }

  export default Marker