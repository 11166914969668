import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, JobOffers } from '../components'
import { Header, Container } from 'semantic-ui-react'
import { Article, ArticleHorizontal } from '../components/section'

import IMG_5997 from '../images/employees/IMG_5997.webp'
import IMG_4545 from '../images/employees/IMG_4545.webp'
import IMG_5999 from '../images/employees/IMG_5999.webp'
import stamp from '../images/mehrZeit.png'
import { CareerFooter } from '../components/career'
import { useNavigate } from 'react-router-dom'

const jobArray = [
  {
    headline: 'Verkauf',
    image: '45C878CC-017F-403D-BAFF-9FCA6EE116F0.webp',
    job: 'Verkäuferin (m/w/d)',
    link: 'Verkauf'
  },
  {
    headline: 'Verkauf',
    image: 'IMG_4549.webp',
    job: 'Fach Geschäfts- Leitung (m/w/d)',
    link: 'Verkauf'
  },
  {
    headline: 'Verkauf',
    image: 'IMG_4546.webp',
    job: 'Bezirks- Leitung (m/w/d)',
    link: 'Verkauf'
  },
  {
    headline: 'Ausbildung',
    image: '57E10DB5-C7FF-4BE9-85E6-A1CC9CC97745.webp',
    job: 'Ausbildung (m/w/d)',
    link: 'Ausbildung'
  },
  {
    headline: 'Versand',
    image: 'IMG_6001.webp',
    job: 'Logistik/ Fahrer (m/w/d)',
    link: 'Logistik'
  },
  {
    headline: 'Bäcker',
    image: 'IMG_5999.webp',
    job: 'Bäcker (m/w/d)',
    link: 'Backstube'
  },
  {
    headline: 'Konditor',
    image: 'IMG_6003.webp',
    job: 'Konditor (m/w/d)',
    link: 'Backstube'
  },
  {
    headline: 'Verwaltung',
    image: 'IMG_5997.webp',
    job: '(Fach) kraft Büro- Management (m/w/d)',
    link: 'Verwaltung'
  },
  {
    headline: 'Verwaltung',
    image: 'IMG_6100.webp',
    job: 'Haustechnik (m/w/d)',
    link: 'Verwaltung'
  }
]

const Employees = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const handleOnJobClick = (link) => {
    navigate({
      pathname: '/jobs',
      search: `state=search`,
      state: { filter: link},
    })
  }

  return (
    <main>
      <section key={'products-home'} id={`#products-home`}>
        <Container>
          <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontSize: '6rem', fontWeight: '300'}} as='h1'>{t(`headline-employees_home`)}</Header>
          <p>
            {t(`content-employees_home`)}
          </p>
        </Container>
      </section>
      <ArticleHorizontal
        id={'home'}
        headline={'Isabella Hoidn'}
        subline={' unser Azubi-Coach'}
        content={['Fachverkäuferin in Dietenheim und seit 2022 Ausbilderin gemäß AEVO und Ausbildungsbeauftragte im Backhaus.', '„Meine Häussler Highlights? Zuletzt natürlich das Angebot für meine Fort- bzw. Weiterbildung zur Ausbilderin (AdA-Prüfung). Jetzt kann ich neben meiner Tätigkeit als Fachverkäuferin auch aktiv unseren Nachwuchs fördern. Und ansonsten generell die Herzlichkeit und unseren Teamspirit. Ich liebe meinen Job und meinen Arbeitgeber - ICH BIN HÄUSSLER! :-)“']}
        image={IMG_5997}
      />
      <Article
        id={'elke'}
        headline={'Elke Goldschmidt'}
        subline={'unsere Snackleitung'}
        content={['Leitung der Snackabteilung in unserer Zentrale in Memmingen, seit 22 Jahren im Backhaus.', '„Das Geheimnis meiner guten Laune bzw. mein Glücksrezept? Ich würde sagen das ist die Einstellung zu den Dingen. Glück ist großartig, aber die Schlüssel im Leben sind Einsatz, Fleiß und Dankbarkeit. Ich schätze mein selbstständiges Arbeiten und den respektvollen Umgang miteinander und vor allem natürlich auch den täglichen Umgang mit Lebensmitteln. Daher bringe ich mich auch jeden Tag zuverlässig und gerne ein.“']}
        image={IMG_4545}
      />
      <ArticleHorizontal
        id={'hakan'}
        headline={'Robert Balogh'}
        subline={'unser Bäckerei Allrounder'}
        content={['Teigmacher und Bäcker in unserer Backstube in Memmingen, seit 12 Jahren im Backhaus.', '„Wir haben Spaß bei der Arbeit, sind ein tolles Team und stellen tolle Produkte her - ich glaube das bringt es ziemlich gut auf den Punkt :) Mir gefällt mein Job, ich fühle mich wohl hier und bisher gab es nichts was wir nicht meistern konnten."']}
        image={IMG_5999}
        card={stamp}
      />
      <JobOffers 
        id={'job-offers'}
        offers={jobArray}
        onItemClick={handleOnJobClick}
      />
      <CareerFooter />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Employees;