import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, addNotification } from "../../redux/actions/";
import { addCartItem } from '../../utils/requestHelper'
import { Card, Container, Divider, Header } from 'semantic-ui-react'
import ProductCard from './productCard';
import { useTranslation } from 'react-i18next';


const ProductList = (props) => {
  const {t} = useTranslation()
  const { activeItem, allergenFilter } = props
  const [ activeCard, setActiveCard ] = useState()
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products)
  const productsCategories = useSelector((state) => state.productsCategories)
  const [filteredCategories, setFilteredCategories ] = useState()

  useEffect(()=>{
    if(productsCategories) {
      let categories = productsCategories.filter(x => x === activeItem)
      if(categories.length === 0)
        setFilteredCategories(productsCategories)
      else
        setFilteredCategories(categories)
    }
  },[activeItem])

  const handleOnAddProduct = async (id) => {
    var result = await addCartItem(id)
    if(result.success === true) {
      dispatch(addToCart())
      dispatch(addNotification(t('haedline-cart'), t('content-add_to_cart'),'info'))
    }
  }

  const handleOnShowMore = (id, ref) => {
    ref.current.scrollIntoView(true)
    setActiveCard(id)
  }

  const handleOnShowLess = () => {
    setActiveCard(null)
  }

  const renderCards = (array) => {
    var items = []
    array = array.sort((a, b) => {
      if (a.ordering > b.ordering) return -1
      return a.ordering < b.ordering ? 1 : 0
    })
    array.forEach(element => {
      items.push(
        <ProductCard
          id={element.id}
          header={element.name}
          description={element.description}
          ingredients={element.ingredients}
          allergenes={element.allergenes}
          calorific={element.calorific}
          nutrition={element.nutrition}
          currency={element.currency}
          price={element.price}
          ordering={element.ordering}
          addItem={handleOnAddProduct}
          activeCard={activeCard}
          allergenFilter={allergenFilter}
          showMore={handleOnShowMore}
          showLess={handleOnShowLess}
        />
      )
    });
    return items
  }

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container key={props.id} id={`#${props.id}`} >
        {filteredCategories && filteredCategories.map((element, index)=>(
          <div style={{marginTop:20}}>
            <Header className={'headline-regular'} as='h1'>{element}</Header>
            <Divider clearing />
            <Card.Group stackable doubling centered>
              {renderCards(products.filter(function(obj) {
                if(element.indexOf(obj.category) === -1)
                  return false;
                else
                  return true;
              }))}
            </Card.Group>
          </div>
        ))}
      </Container>
    </section>
  );
}

export default ProductList;