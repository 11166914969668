import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Image, Container, Header } from 'semantic-ui-react'
import { DrawButton } from '../button'

import card from './images/jobs.jpg'
import background from './images/haeussler-work.webp'

const Training = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const handleOnClickLink = () => {
    navigate(`/jobs`)
  }

  const style = {
    section : {
      padding: '30px 0',
    },
    img : {
      backgroundImage: `url(${background})`
    }
  }

  return(
    <section style={style.section} key={props.id} id={`#${props.id}`} >
      <Container fluid >
        <article className='article horizontal img-first'>
          <div className='article__txt' >
            <Header className={'headline-regular'} as='h1'>{t(`haedline-training`)}</Header>
            <Header className={'headline-bag'} as='h3'>{t(`sub-headline-training`)}</Header>
            <p>
              {t(`content-training`)}
            </p>
            <p>
              <DrawButton
                name={t(`button-search_now`)}
                onClick={handleOnClickLink}
              />
            </p>
          </div>
          <div className='article__img' >
            <div className="img" style={style.img}></div>
            <div className="img-stamp">
              <Image alt={t(`haedline-training`)} size='large' src={card} />
            </div>
          </div>
        </article>
      </Container>
    </section>
  );
} 

export default Training;