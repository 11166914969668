import React from 'react'
import { Container, Pagination, Item } from 'semantic-ui-react'

import CareerListItem from './careerListItem'

const CareerList = (props) => {

  if(!props.jobData)
    return null

  return(
    <section key={props.id} id={`#${props.id}`}>
      <Container fluid >
        <Pagination
          activePage={props.pignation.activePage}
          onPageChange={props.setPignation}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={props.pignation.totalPages}
        />
        <Item.Group link relaxed >
          {props.jobData.data && props.jobData.data.map((element, i) => {    
           return (<CareerListItem key={`application-item-${i}`} jobId={element.jobId} title={element.name} location={element.location} department={element.department} subscription={element.subscription} btn={props.onJobSelect}/>) 
        })}
        </Item.Group>
        <Pagination
          activePage={props.pignation.activePage}
          onPageChange={props.setPignation}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={props.pignation.totalPages}
        />
      </Container>
    </section>
  );
}

export default CareerList;