import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Image, Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";

import paypal from '../../images/1280px-PayPal_logo.svg.png'
import google from '../../images/google-play-badge.png'
import apple from '../../images/app-store-badge.svg'

import './footer.scss'

const Services = (props) => {
  const {t} = useTranslation()

  return(
    <Grid.Column textAlign={props.size < 750 && 'center'}>
      <Grid stackable columns={1}>
        <Grid.Column >
          <Menu text vertical inverted style={{margin: 'auto'}}>
            <Menu.Item header style={{fontSize: '1.4rem'}} className={'footer-navigation'} >{t(`menu-item-app`)}</Menu.Item>
            <Menu.Item>
              <Image size='small' to={'https://play.google.com/store/apps/details?id=de.firstconcept.haeussler&hl=de'} target='_blank' as={Link} src={google} />
            </Menu.Item>
            <Menu.Item>
              <Image size='small' to={'https://apps.apple.com/de/app/h%C3%A4ussler/id1565347806'} target='_blank' as={Link} src={apple} />
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column >
          <Menu text vertical inverted style={{margin: 'auto'}}>
            <Menu.Item header style={{fontSize: '1.4rem'}} className={'footer-navigation'} >{t(`menu-item-payment`)}</Menu.Item>
            <Menu.Item>
              <Image
                src={paypal}
                size='small'
              />
            </Menu.Item>
          </Menu>
        </Grid.Column>     
      </Grid>
    </Grid.Column>
  );
}

export default Services;