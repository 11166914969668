import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom'

const FragmentFrame = (props) => {
  const [ content, setContent ] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(()=>{
    const { pathname, search } = location
    const fetchData = async (url) => {
      var config = {
        method: 'get',
        url: `${(window._env_.REACT_APP_API_URL).replace('/api','')}${url}`,
      };
      try {
        var result = await axios(config)
        if(result.status == 200 || result.status == 302){
          navigate('/')
        }
      }
      catch(error) {
        setContent(error)
      }
    }
    if(location) {
      fetchData(`${pathname}${search}`)
    }
  },[location])

  function createMarkup() {
    return {__html: content};
  }

  if(!content)
    return null
  return(
    <div dangerouslySetInnerHTML={createMarkup()} />
  )
}
export default FragmentFrame