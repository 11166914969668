import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, Slider } from '../components'
import { Container, Header } from 'semantic-ui-react'

import slider from '../images/slideshow'


const Business = () => {
  const {t} = useTranslation()
  return (
    <main>
      <section key='section_home' id={`#section_home`} >
        <Container>
          <Header className={'headline-regular'} as='h1'>{t(`haedline-dream`)}</Header>
          <p>{t(`content-dream-01`)}</p>
          <p>{t(`content-dream-02`)}</p>
          <p>{t(`content-dream-03`)}</p>
        </Container>
      </section>
      <section key={`#section_home_slider`} id={`#section_home_slider`}>
        <Container>
          <Slider auto heading={t(`headline-slider-home`)} slides={slider}/>
        </Container>
      </section>
      <section key={`#$section_home_02`} id={`#$section_home_02`}>
        <Container>
          <p>{t(`content-dream-04`)}</p>
          <p>{t(`content-dream-05`)}</p>
          <p>{t(`content-dream-06`)}</p>
          <p>{t(`content-dream-07`)}</p>
          <p>{t(`content-dream-08`)}</p>
          <p>{t(`content-dream-09`)}</p>
          <p>{t(`content-dream-10`)}</p>
          <p>{t(`content-dream-11`)}</p>
          <Header className={'headline-bag'} as='h3'>{t(`content-dream-12`)}</Header>
        </Container>
      </section>
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Business;