import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Message, Form, Grid } from 'semantic-ui-react'
import { InputField } from '../'
import { postContact } from '../../utils/requestHelper'
import { DrawButton } from '../button'

const ContactForm = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [data, setData ] = useState({
    name: null,
    mail: null,
    phone: null,
    subject: null,
    message: null,
    verificationState: null,
    mailError: null,
    nameError: null,
    messageError: null,
    subjectError: null,
    phoneError: null,
    iAgree: false,
    iAgreeError: false
  })

  const [send,setSend] = useState(false)

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeCheckbox = () => {
    setData(prevData => ({
        ...prevData,
        iAgree: !data.iAgree
    }))
  }

  const validateForm = () => {
    const errors = {
      mailError: null,
      nameError: null,
      subjectError: null,
      messageError: null,
      phoneError: null,
      verificationState: true
    }

    if(!data.name){
      errors.nameError = t('error-empty-name')
      errors.verificationState = false
    }
    if(!data.subject){
      errors.subjectError = t('error-empty-subject')
      errors.verificationState = false
    }
    if(!data.phone){
      errors.phoneError = t('error-empty-phone')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }
    if(!data.message){
      errors.messageError = t('error-empty-message')
      errors.verificationState = false
    }
    if(!data.iAgree){
      errors.iAgreeError = true
      errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnSubmit = async () => {
    if(validateForm()){
      var result = await postContact(data)
      if(result.message === 'contact successfully created') {
        setSend(true);
        setTimeout(() => {
          navigate('/')
        },5000);
      }
    }
  }

  return(
    <section key={props.id} id={`#${props.id}`}>
      <Container>
        { send !== true ? <Form>
          <Form.Group>
            <InputField 
              handleOnChange={handleOnChange}
              value={data.name}
              valueError={data.nameError}
              id="name"
              type="text"
              label={t('input-label-name')}
              required
            />
            <InputField 
              handleOnChange={handleOnChange}
              value={data.phone}
              valueError={data.phoneError}
              id="phone"
              type="text"
              label={t('input-label-phone')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={handleOnChange}
              value={data.mail}
              valueError={data.mailError}
              id="mail"
              type="mail"
              label={t('input-label-mail')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={handleOnChange}
              value={data.subject}
              valueError={data.subjectError}
              id="subject"
              type="text"
              label={t('input-label-subject')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={handleOnChange}
              value={data.message}
              valueError={data.messageError}
              id="message"
              type="textarea"
              label={t('input-label-message')}
              rows={8}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={handleOnChangeCheckbox}
              id="iAgree"
              type="checkbox"
              label={data.iAgreeError ? <label style={{color: '#7F1019'}}>{t('input-label-policy')}</label> : <label>{t('input-label-policy')}</label>}
              required
            />
          </Form.Group>
          <Form.Group>
            <DrawButton
              name={t(`button-send`)}
              onClick={handleOnSubmit}
            />
          </Form.Group>
        </Form> :
        <Grid.Column>
          <Message size='huge' positive>
            <Message.Header>{t(`headline-contact-success`)}</Message.Header>
            <p>{t(`content-contact-success`)}</p>
          </Message>
        </Grid.Column>}
      </Container>
    </section>
  );
}

export default ContactForm;