import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Grid, Menu, Checkbox, Segment, Image, Button, Modal, Header, Icon } from 'semantic-ui-react'
import { InputField } from '..'
import { DrawButton } from '../button/'

import svg_snacks from './assets/cake-svg.svg'
import svg_buns from './assets/buns-svg.svg'
import svg_bread from './assets/bread-svg.svg'
import svg_cupcake from './assets/food-svg.svg'
import svg_sandwich from './assets/sandwich-svg.svg'
import svg_croissant from './assets/croissant-svg.svg'
import img_modal from '../../images/logo.svg'
import { useTranslation } from 'react-i18next'

const ProductFilter = (props) => {
  const {t} = useTranslation()
  const productsCategories = useSelector((state) => state.productsCategories)
  const products = useSelector((state) => state.products)
  const { activeItem, onClickItem } = props
  const [ showFilter, setShowFilter ] = useState(false)

  const renderArray = () => {
    const array = [...new Set(products.map(item => item.allergenes && item.allergenes.split(',')))]
    let unique = [...new Set(array.flat(1))]
    var items = []
    unique.forEach(element => {
      if(element)
        items.push(
          <Grid.Column style={{display: 'flex'}}>
            <Checkbox 
              toggle 
              value={element} 
              id={element} 
              label={''} 
              id={`allergen-filter-element-#${element}`} 
              onChange={props.onSelectAllergen} 
              defaultChecked={props.allergenFilter.includes(element)}  
            />
            <label style={{color: '#fff'}}>{element}</label> 
          </Grid.Column>
        )
    })
    return items
  }

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container key={props.id} id={`#${props.id}`} className={'scroll-enabled'} >
        <Menu  pointing secondary icon='labeled'>
          <Menu.Item
            key={`product-filter-element-#$all`}
            name={'Alle'}
            active={activeItem === null}
            onClick={()=>onClickItem(null)}
          >
            {`Alle`}
          </Menu.Item>
        {productsCategories && productsCategories.sort(function (a, b) {
            if(a === b) return 0;
            if (a === 'Partyfood') return -1;
            if (b === 'Partyfood') return 1;

            if (a < b)
                return -1;
            if (a > b)
                return 1;
            return 0;
          }).map((element, index)=>{
          let clip = null
          if(element === 'Süßes')
            clip = svg_snacks
          else if(element === 'Feingebäck')
            clip = svg_croissant
          else if(element === 'Brötchen')
            clip = svg_buns
          else if(element === 'Brote')
            clip = svg_bread
          else if(element === 'Snacks')
            clip = svg_sandwich
          else if(element === 'Partyfood')
            clip = svg_cupcake
          return(        
            <Menu.Item
              key={`product-filter-element-#${index}`}
              name={element}
              active={activeItem === element}
              onClick={()=>onClickItem(element)}
            >
                <div id={`product-filter-element-icon-#${index}`} style={{height: 40,content: `url(${clip})`}}></div>
              {element}
            </Menu.Item>
          )
        })
        }
        </Menu>
      </Container>
      <Container style={{marginTop: 15}}>
        <Grid stackable columns={1}>
          <Grid.Column style={{textAlign: 'center'}}>
            <DrawButton
              name={t('button-allergen_filter')}
              fontSize='1.1rem'
              onClick={()=>setShowFilter(!showFilter)}
            />
          </Grid.Column>
          {props.allergenFilter.length > 0 && <Grid.Column style={{textAlign: 'center'}}>
            <p style={{color: '#21ba45'}}>{t('content-allergen_filter-active')}</p>
          </Grid.Column>}
        </Grid>
      </Container>
      <Modal
        basic
        onClose={() => setShowFilter(false)}
        onOpen={() => setShowFilter(true)}
        open={showFilter}
        size='small'
      >
        <Image centered src={img_modal} size='small' />
        <Header icon className={'headline-regular'} as='h1'>{t('headline-allergen_filter')}</Header>
        <Modal.Content>
          <p style={{textAlign: 'center', fontFamily: 'var(--main-font)', fontSize: '1.4rem', fontWeight: 300}}>
            {t('content-allergen_filter')}
          </p>
          <Grid columns={2} stackable >
            {renderArray()}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' inverted onClick={() => setShowFilter(false)}>
            <Icon name='checkmark' /> {t('button-accept')}
          </Button>
        </Modal.Actions>
      </Modal>
    </section>
  );
}

export default ProductFilter;