import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'semantic-ui-react'
import { Instagram, Slider } from '../components'

import { Article, ArticleHorizontal } from '../components/section'

import bg_breze from '../images/image-breze-haeussler.webp'
import background from '../images/image-catering-haeussler.webp'
import stamp from '../images/mehrHeimat.png'

import slider from '../images/corporation'

const Business = () => {
  const {t} = useTranslation()

  return (
    <main>
      <Article
        id={'catering'}
        headline={t(`haedline-catering`)}
        subline={t(`sub-headline-catering`)}
        content={t(`content-catering`)}
        image={background}
        card={stamp}
      />
      <ArticleHorizontal
        id={'affiliate'}
        headline={t(`haedline-affiliate`)}
        subline={t(`sub-headline-affiliate`)}
        content={t(`content-affiliate`)}
        image={bg_breze}
        card={stamp}
      />
      <section key={'corporation-slider'} id={`#corporation-slider`}>
        <Container>
          <Slider auto heading={t(`headline-slider-home`)} slides={slider}/>
        </Container>
      </section>
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Business;