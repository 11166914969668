import React from "react";
import ApplicationStart from "./applicationStart";
import ApplicationPolicy from "./applicationPolicy";
import ApplicationUpload from "./applicationUpload";
import ApplicationPersonal from "./applicationPersonal";
import ApplicationAddress from "./applicationAddress";
import ApplicationInterview from "./applicationInterview";
import ApplicationSuccess from "./applicationSuccess";

const Applications = ({show, job, progress, question, onClickNextQuestion, onClickPrevQuestion, onClickStart, onClickNext, onClickBack, handleOnSubmit, handleOnFileChange, handleOnInputChange, handleOnToggleIAgree, handleOnToggleTalentPool, data, step}) => {
  const renderStep = (value) => {
    switch (value) {
      case 0:
        return <ApplicationStart
          show={show}
          name={job.name}
          handleOnSubmit={onClickStart}
        />
      case 1:
        return <ApplicationInterview
          show={show}
          interview={job.interview[question]}
          handleOnSubmit={onClickNextQuestion}
          handleOnBack={onClickPrevQuestion}
        />
      case 2:
        return <ApplicationPersonal
          show={show}
          handleOnSubmit={onClickNext}
          surname={data.surname}
          surnameError={data.surnameError}
          givenname={data.givenname}
          givennameError={data.givennameError}
          mail={data.mail}
          mailError={data.mailError}
          phone={data.phone}
          handleOnChange={handleOnInputChange}
          handleOnBack={onClickBack}
        />
      case 3:
        return <ApplicationAddress
          show={show}
          handleOnSubmit={onClickNext}
          postalCode={data.postalCode}
          postalCodeError={data.postalCodeError}
          location={data.location}
          locationError={data.locationError}
          streetAddress={data.streetAddress}
          streetAddressError={data.streetAddressError}
          handleOnChange={handleOnInputChange}
          handleOnBack={onClickBack}
        />
      case 4:
        return <ApplicationUpload
          show={show}
          handleOnSubmit={onClickNext}
          handleOnFileChange={handleOnFileChange}
          attachments={data.attachments}
          handleOnBack={onClickBack}
        />
      case 5:
        return <ApplicationPolicy
          show={show}
          iAgree={data.iAgree}
          iAgreeError={data.iAgreeError}
          verificationState={data.verificationState}
          handleOnSubmit={handleOnSubmit}
          handleOnToggleTalentPool={handleOnToggleTalentPool}
          handleOnToggleIAgree={handleOnToggleIAgree}
          handleOnBack={onClickBack}
        />
      case 9999:
        return <ApplicationSuccess show={show} />
      default:
        return <ApplicationStart
          show={show}
          handleOnSubmit={onClickNext}
        />
    }
  }

  return(
    <React.Fragment>
      {renderStep(step)}
    </React.Fragment>
  )
}

export default Applications