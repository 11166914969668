import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Header } from 'semantic-ui-react'
import config from '../config/main.json'

const Conditions = () => {
  const {t} = useTranslation()

  return (
    <main>
      <Container className={'section'} key={`container-conditions`} id={`container-conditions`}>
        <Header className={'headline-regular'} as='h1'>{t('page-conditions-headline')}</Header>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-scope')}</Header>
        <p>{t('page-conditions-scope', {company: config.company})}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-contract')}</Header>
        <p>{t('page-conditions-contract-content-01')}</p>
        <p>{t('page-conditions-contract-content-02')}</p>
        <p>{t('page-conditions-contract-content-03')}</p>
        <p>{t('page-conditions-contract-content-04')}</p>
        <p>{t('page-conditions-contract-content-05')}</p>
        <p>{t('page-conditions-contract-content-06')}</p>
        <p>{t('page-conditions-contract-content-07')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-availability')}</Header>
        <p>{t('page-conditions-availability-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-prices')}</Header>
        <p>{t('page-conditions-prices-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-payment')}</Header>
        <p>{t('page-conditions-payment-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-warranty')}</Header>
        <p>{t('page-conditions-warranty-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-conditions-headline-cancellation')}</Header>
        <p>{t('page-conditions-cancellation-content-01')}</p>
        <p>{t('page-conditions-cancellation-content-02')}</p>
        <p>{t('page-conditions-cancellation-content-03')}</p>
        <p>{t('page-conditions-cancellation-content-04')}</p>
        <p>{t('page-conditions-cancellation-content-05')}</p>
        <p>{config.company}</p>
        <p>{config.streetAddress}</p>
        <p>{config.postalCode} {config.locality}</p>
        <p>{t('page-conditions-cancellation-content-06')}</p>
      </Container>
    </main>
  );
}

export default Conditions;