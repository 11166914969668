import React from 'react'
import './buttons.scss'

const PhoneButton = (props) => {

  return(
    <a 
      href={`tel:${props.phone.replace(/[|&;$%@"<>()\-,]/g, "")}`} 
      target="blank" 
      className="btn-styled-icon spanish-white icon-phone" 
    >
			{`${props.phone}`}
    </a>
  );
}

export default PhoneButton