import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Image, Icon, Container, Header } from 'semantic-ui-react'

const imageArray = ["152874597_197691695214796_8228054713207286494_n.jpg", "155801836_999070543953975_2865885356662385266_n.jpg", "159121885_1175080532911510_1943013552531421292_n.jpg", "169419928_2817638758449763_9111991754849740876_n.jpg", "194375247_509446860194959_6440801005994894128_n.jpg", "199941524_759186331425419_9215774648624106004_n.jpg", "210190822_875730223025208_7746331106847571933_n.jpg", "233804001_289634389601402_4880300220215146561_n.jpg", "241217158_360301919140187_2628307934928625065_n.jpg"]

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }

const InstagramImage = ({src, alt}) => {
  return(
    <Grid.Column>
      <Image src={src} alt={alt} />
    </Grid.Column>
  )
}

const Instagram = (props) => {
  const {t} = useTranslation()
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container fluid key={props.id} id={`#${props.id}`} >
        <Header className={'headline-regular'}  textAlign='center' as='h1'>{t(`headline-instagram`)}</Header>
        <Header as='h4' icon textAlign='center'>
          <Icon circular inverted name='instagram' />
        </Header>
        <Grid container columns={3}>
          {imageArray.map((element, index) => {
            return(<InstagramImage key={`instagram-image-#${index}`} alt={`instagram-image-#${index}`} src={images[element]} />)
          })}
        </Grid>
      </Container>
    </section>
  );
}

export default Instagram;