import React, { useState, useEffect, useLayoutEffect } from 'react'
import background from '../../images/hero.webp'

const Hero = (props) => {
  const [offset, setOffset] = useState(0)
  const [size, setSize] = useState([0, 0])
  const headlineArray = ['sind wir']
  const [ headline, setHeadline ] = useState()

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const style = {
    hero : {
      width: "100%",
      backgroundSize: 'cover',
      height: "100vh",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden",
    },
    parallax : {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: -1,
      transform: `translateY(${offset * 0.5}px)`
    },
    headline: {
      color: 'rgba(255,255,255,.9)',
      transform: 'rotate(-8deg)',
      fontSize: '20vw',
      fontWeight: '300'
    },
    subline: {
      color: 'rgba(255,255,255,.9)',
      fontFamily: 'Alfa Slab One',
      fontSize: '8vw',
      fontWeight: '300',
      lineHeight: '1'
    },
    subContainer: {

    },
    container: {
      top: size < 600 ? `calc(100vh - 30vh)` : `calc(100vh - 50vh)`,
      position: 'absolute',
      textAlign: 'center'
    }
  }

  useEffect(()=>{
    var item = headlineArray[Math.floor(Math.random()*headlineArray.length)]
    setHeadline(item)
  },[])

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [offset])

  return(
    <section key={props.id} id={`#${props.id}`} style={style.hero} >
      <img
        src={background}
        alt={props.headline}
        style={style.parallax}
      />
      <div style={style.container} className="container">
        <div style={style.subContainer}>
          <h2 style={style.subline}>Was uns ausmacht</h2>
        </div>
        <div>
          <h2 className={'headline-bag'} style={style.headline}>{headline}</h2>
        </div>
      </div>
    </section>
  )
}

export default Hero;