import image1 from './slide_01.webp'
import image2 from './slide_02.webp'
import image3 from './slide_03.webp'
import image4 from './slide_04.webp'

const slideData = [
  {
    index: 0,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Shop now',
    src: image1
  },
  {
    index: 1,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Book travel',
    src: image2
  },
  {
    index: 2,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Listen',
    src: image3
  },
  {
    index: 3,
    headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
    button: 'Get Focused',
    src: image4
  }
]

export default slideData