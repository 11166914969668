import React from 'react'
import { ContactForm, Instagram, ContactHeader, Training } from '../components'

const Contact = () => {

  return (
    <main>
      <ContactHeader
        id={'header'}
      />
      <ContactForm
        id={'contact'}
      />
      <Training
        id={'jobportal'}
      />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Contact;