import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Container, Header } from 'semantic-ui-react'
import { DrawButton } from '../button'

import card from './images/jobs.jpg'
import background from './images/haeussler-work.webp'

const CareerFooter = (props) => {
  const {t} = useTranslation()

  const handleOnClickLink = (link) => {
    window.open(`/application/${link}`, '_blank')
  }

  const style = {
    section : {
      padding: '30px 0'
    },
    img : {
      backgroundImage: `url(${background})`
    }
  }

  return(
    <section style={style.section} key={props.id} id={`#${props.id}`} >
      <Container fluid >
        <article className='article horizontal img-first'>
          <div className='article__txt' >
            <Header className={'headline-regular'} as='h1'>{t(`haedline-initiative_application`)}</Header>
            <Header className={'headline-bag'} as='h3'>{t(`sub-headline-initiative_application`)}</Header>
            <p>
              {t(`content-initiative_application`)}
            </p>
            <p>
              <DrawButton
                name={t(`button-application`)}
                onClick={()=>handleOnClickLink('f6541f74f58c4897955b01dfb8b2f36c')}
              />
            </p>
          </div>
          <div className='article__img' >
            <div className="img" style={style.img}></div>
            <div className="img-stamp">
              <Image alt={t(`snackCard-header`)} size='large' src={card} />
            </div>
          </div>
        </article>
      </Container>
    </section>
  );
} 

export default CareerFooter;