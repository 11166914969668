import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Header } from 'semantic-ui-react'
import config from '../config/main.json'

const Policy = () => {
  const {t} = useTranslation()

  return (
    <main>
      <Container className={'section'} key={`container-about`} id={`container-about`}>
        <Header className={'headline-regular'} as='h1'>{t('page-policy-headline')}</Header>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-headline-information')}</Header>
        <p>{t('page-policy-information')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-elevation-headline')}</Header>
        <p>{t('page-policy-elevation-content-01')}</p>
        <p>{t('page-policy-elevation-content-02')}</p>
        <p>{t('page-policy-elevation-content-03')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-headline-responsible')}</Header>
        <p>{t('page-policy-responsible-content')}</p>
        <p>{config.company}</p>
        <p>{config.streetAddress}</p>
        <p>{config.postalCode} {config.locality}</p>
        <p><strong>{t('page-about-represented')}</strong></p>
        <p>{config.businessOrganisation}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-headline-protection_officer')}</Header>
        <p>{t('page-policy-protection_officer-content', { givenname: config.protectionOfficer.givenname, surname: config.protectionOfficer.surname, streetAddress: config.protectionOfficer.streetAddress, postalCode: config.protectionOfficer.postalCode, location: config.protectionOfficer.location })}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-headline-hosting')}</Header>
        <p>{t('page-policy-hosting-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-collection-headline')}</Header>
        <p>{t('page-policy-collection-content-01')}</p>
        <p>
          <ul>
            <li>{t('page-policy-collection-content-ul-01')}</li>
            <li>{t('page-policy-collection-content-ul-02')}</li>
            <li>{t('page-policy-collection-content-ul-03')}</li>
            <li>{t('page-policy-collection-content-ul-04')}</li>
            <li>{t('page-policy-collection-content-ul-05')}</li>
            <li>{t('page-policy-collection-content-ul-06')}</li>
            <li>{t('page-policy-collection-content-ul-07')}</li>
          </ul>
        </p>
        <p>{t('page-policy-collection-content-02')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-contact-headline')}</Header>
        <p>{t('page-policy-contact-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-mail-headline')}</Header>
        <p>{t('page-policy-mail-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-phone-headline')}</Header>
        <p>{t('page-policy-phone-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-whatsapp-headline')}</Header>
        <p>{t('page-policy-whatsapp-content_01')}</p>
        <p>{t('page-policy-whatsapp-content_02')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-order-headline')}</Header>
        <p>{t('page-policy-order-content')}</p>
        <p>{t('page-policy-order-content-01')}</p>
        <p>{t('page-policy-order-content-01-dhl')}</p>
        <p>{t('page-policy-order-content-01-dpd')}</p>
        <p>{t('page-policy-order-content-01-ups')}</p>
        <p>{t('page-policy-order-content-02')}</p>
        <p>{t('page-policy-order-content-02-paypal')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-newsletter-headline')}</Header>
        <p>{t('page-policy-newsletter-content')}</p>
        <p>{t('page-policy-newsletter-content-01')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-guest-registration-headline')}</Header>
        <p>{t('page-policy-guest-registration-content')}</p>
        <p>{t('page-policy-guest-registration-content-01')}</p>
        <p>{t('page-policy-guest-registration-content-02')}</p>
        <p>{t('page-policy-guest-registration-content-03')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-carrier-headline')}</Header>
        <p>{t('page-policy-carrier-content_01')}</p>
        <p>{t('page-policy-carrier-content_02')}</p>
        <p>{t('page-policy-carrier-content_03')}</p>
        <p>{t('page-policy-carrier-content_04')}</p>
        <p>{t('page-policy-carrier-content_05')}</p>
        <p>{t('page-policy-carrier-content_06')}</p>
        <p>{t('page-policy-carrier-content_07')}</p>
        <p>{t('page-policy-carrier-content_08')}</p>
        <p>{t('page-policy-carrier-content_09')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-policy-plugins-headline')}</Header>
        <p>{t('page-policy-plugins-content')}</p>
        <p>{t('page-policy-plugins-content-01')}</p>
        <p>{t('page-policy-plugins-content-maps')}</p>
      </Container>
    </main>
  );
}

export default Policy;