import React, { useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import { Grid, Image, Icon } from 'semantic-ui-react'
import Logo from '../../images/logo.svg'
import './footer.scss'
import Navigation from './navigation'
import Services from './services'

const Footer = (props) => {
  const {t} = useTranslation()
  const footerMenu = useSelector((state) => state.footerMenu)
  const navigate = useNavigate();
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleOnClickLink = (link) => {
    navigate(`/${link}`)
  }

  const renderNavigation = () => {
    var container = []
    if(footerMenu){
      var categories = footerMenu.map( (value) => value.section).filter( (value, index, _arr) => _arr.indexOf(value) === index)
      categories.forEach((element, index) => {
        var objects = footerMenu.filter((object) => { return object.section === element })
        container.push(
          <Navigation
            key={`footer-navigation-#${index}`}
            section={element}
            menuItems={objects}
            size={size}
          />
        )
      })
    }
    return container
  }

  return(
    <footer key={props.id} id={`#${props.id}`}>
      <Grid stackable columns={3}>
        <Grid.Column></Grid.Column>
        <Grid.Column textAlign='center'>
          <Image src={Logo} size='medium' centered alt="header logo" onClick={()=>handleOnClickLink('')}/>
        </Grid.Column>
        <Grid.Column textAlign={size > 770 ? 'left' : 'center'} verticalAlign='bottom'>
          <Icon size='big' link onClick={()=>window.open('https://www.instagram.com/backhaus_haeussler/')} circular name='instagram' className={'footer-sozial'}/>
          <Icon size='big' link onClick={()=>window.open('https://www.facebook.com/BackhausHaeussler')} circular name='facebook f' className={'footer-sozial'}/>
        </Grid.Column>
      </Grid>
      <Grid stackable centered columns={4} >
        {renderNavigation()}
        <Services
          size={size}
        />
      </Grid>
      <Grid container columns={3}>
        <Grid.Column textAlign='right'>
          <span onClick={()=>handleOnClickLink('about')} className={'footer-links'}>{t('footer-link-about')}</span>
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <span onClick={()=>handleOnClickLink('policy')} className={'footer-links'}>{t('footer-link-policy')}</span>
        </Grid.Column>
        <Grid.Column textAlign='left'>
          <span onClick={()=>handleOnClickLink('conditions')} className={'footer-links'}>{t('footer-link-conditions')}</span>
        </Grid.Column>
      </Grid>
    </footer>
  );
}

export default Footer;