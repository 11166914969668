import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Image, Transition } from 'semantic-ui-react';
import logo from '../../images/jobs.jpg'

const ApplicationSuccess = (props) => {
  const {t} = useTranslation()
  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Image
          src={logo}
          size='small'
          centered
          circular
        />
        <Header className={'headline-regular'} as='h1'>{t('headline-application-success')}</Header>
        <p>
          {t('content-application-success')}
        </p>
      </section>
    </Transition>
  )
}

export default ApplicationSuccess