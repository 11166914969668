import { NETWORK_START, NETWORK_STOP, NETWORK_ERROR, STORES_LOAD, STORES_SETME } from "../constants/action-types";

import { fetchLocations } from '../../utils/requestHelper'
import { setItem } from '../../utils/storageHelper'
import { store } from '../store';

export function fetchStores(force, search, attributes){
  return async (dispatch) => {
    try {
      const state = store.getState();
      if(state.stores &&!force)
        dispatch({ type: STORES_LOAD, result: state.stores })
      else {
        dispatch({ type: NETWORK_START });
        const result = await fetchLocations(null, search, attributes)
        if(result.success === true){
          dispatch({ type: STORES_LOAD, result: result.data })
        }
        dispatch({ type: NETWORK_STOP });
      }
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function setMyStore(storeId){
  return async (dispatch) => {
    setItem('myStore',storeId)
    dispatch({type: STORES_SETME, result: storeId})
  }
}