import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Image, Transition } from 'semantic-ui-react';
import { DrawButton } from '../button';

import logo from '../../images/jobs.jpg'

const ApplicationStart = (props) => {
  const {t} = useTranslation()

  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Image
          src={logo}
          size='small'
          centered
          circular
        />
        <Header className={'headline-regular'} as='h1'>{t('headline-application_start') + props.name}</Header>
        <p>
          {t('content-application_start')}
        </p>
        <DrawButton
          name={t(`button-application`)}
          onClick={props.handleOnSubmit}
        />
      </section>
    </Transition>
  )
}

export default ApplicationStart