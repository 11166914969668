import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Item } from 'semantic-ui-react'

const OrderItem = (props) => {
  const {t} = useTranslation()
  return(
    <Item>
      <Item.Content verticalAlign='middle'>
        <Item.Header  style={{fontFamily: 'Avenir Next Codensed Regular'}}>{props.name}</Item.Header>
        <Item.Meta>
            <span style={{fontFamily: 'Bradley Hand'}} >{props.description}</span>
        </Item.Meta>
        <Item.Extra className='item-total'>
            <Header floated='right' style={{fontFamily: 'Bradley Hand', fontSize: '1.3rem', fontWeight: '600'}} as='h3'>{props.qty} x {props.total} {t(`currency-${props.currency}`)}</Header>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}

export default OrderItem;