import axios from 'axios';
import { Buffer } from 'buffer';

import { getItem, getWithExpiry, setWithExpiry, removeItem } from './storageHelper'
const auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")

export const fetchLocations = async (tiny, search, attributes) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations?`

  if(tiny)
    url += `&tiny=${true}`

  if(attributes){
    attributes.forEach(element => {
      url += `&attributes=${element}`
    });
  }

  if(search)
    url += `&search=${search}`
    
  
  var config = {
    method: 'get',
    url: url,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  var result = await axios(config)
  return result.data
}

export const fetchLocation = async (storeId) => {
  
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${storeId}`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  var result = await axios(config)
  return result.data
}

export const fetchProductsCategories = async () => {
  
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/products/categories`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  var result = await axios(config)
  return result.data
}

export const fetchProducts = async (category, regional, biological, ordering) => {
  
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/products?`

  if(category)
    url += `category=${category}`
  if(regional)
    url += `regional=${regional}`
  if(biological)
    url += `biological=${biological}`
  if(ordering)
    url += `ordering=${ordering}`

  var config = {
    method: 'get',
    url: url,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  var result = await axios(config)
  return result.data
}

export const postContact = async (contact) => {
  var storeId = getItem('myStore') || 'a1243d198b8c47898cb052abd23611d9'
  
  var data = JSON.stringify({
    "name": contact.name,
    "mail": contact.mail,
    "phone": contact.phone,
    "subject": contact.subject,
    "message": contact.message
  });
  var config = {
    method: 'post',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${storeId}/contacts`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    data: data
  };
  var result = await axios(config)
  return result.data
}

export const fetchCart = async (pickupDate) => {
  var cartId = getWithExpiry('cartId') || null
  if(cartId) {
    var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/carts/${cartId}`
    if(pickupDate)
      url += `?pickupDate=${pickupDate}`
    var config = {
      method: 'get',
      url: url,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    if(result.data.success === true) {
      setWithExpiry('cartId', result.data.data.cartId, 864000)
    }
    return result.data
  }
  else {
    return null
  }
}

export const checkoutCart = async (cartId, order) => {
  
  var data = JSON.stringify({
    "givenname": order.givenname,
    "surname": order.surname,
    "pickupStore": order.pickupStore,
    "pickupDate": order.pickupDate,
    "pickupTime": order.pickupTime,
    "mail": order.mail,
    "phone": order.phone,
    "notes": order.notes
  });
  var config = {
    method: 'post',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/carts/${cartId}/checkout`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    data: data
  };
  var result = await axios(config)
  if(result.data.success === true)
    removeItem('cartId')
  return result.data
}

export const addCartItem = async (productId) => {
  var cartId = getWithExpiry('cartId') || 'undefinied'
  
  var data = JSON.stringify({
    "productId": productId
  });
  var config = {
    method: 'post',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/carts/${cartId}/product`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    data : data
  };
  var result = await axios(config)
  if(result.data.success === true)
    setWithExpiry('cartId', result.data.data.cartId, 864000)
  return result.data
}

export const removeCartItem = async (productId) => {
  var cartId = getWithExpiry('cartId') || null
  if(cartId) {
    
    var config = {
      method: 'delete',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/carts/${cartId}/product/${productId}`,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      }
    };
    var result = await axios(config)
    if(result.data.success === true)
      setWithExpiry('cartId', result.data.data.cartId, 864000)
    return result.data
  }
  else {
    return null
  }
}

export const fetchJob = async (jobId) => {
  
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetchJobs = async (filter, limit, skip) => {
  var url = `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs?isActive=true`
  if(filter)
    url += `&filter=OR(location:${filter})&filter=OR(name:${filter})&filter=OR(identifier:${filter})&filter=OR(department:${filter})`
  if(skip)
    url += `&skip=${skip}`
  if(limit)
    url += `&limit=${limit}`
    
  var config = {
    method: 'get',
    url: url,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const uploadApplication = async (jobId, application) => {
  try{
    var data = JSON.stringify({
      "givenname": application.givenname,
      "surname": application.surname,
      "mail": application.mail,
      "phone": application.phone,
      "postalCode": application.postalCode,
      "location": application.location,
      "streetAddress": application.streetAddress,
      "attributes": application.attributes,
      "interview": application.interview
    });
    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/applications`,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      },
      data: data
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const uploadApplicationAttachment = async (jobId, applicationId, file) => {
  try{
    let formData = new FormData();
    formData.append("file", file);

    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/applications/${applicationId}/upload`,
      headers: { 
        Authorization: 'Basic ' + auth,
        "Content-Type": "multipart/form-data"
      },
      data : formData
    };
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const fetchReauthorizeOrder = async (orderId) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/orders/${orderId}/reauthorize`,
    headers: { 
      Authorization: 'Basic ' + auth
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
} 

export const fetchOrder = async (orderId, mail) => {
  var config = {
    method: 'get',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/orders/${orderId}?mail=${mail}`,
    headers: { 
      Authorization: 'Basic ' + auth
    }
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const patchOrder = async (order) => {
  var data = JSON.stringify(order);
  var config = {
    method: 'patch',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/orders`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}

export const postJobStatistic = async (jobId, type) => {
  var data = JSON.stringify({type:type});
  var config = {
    method: 'post',
    url: `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/jobs/${jobId}/statistics`,
    headers: { 
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    var result = await axios(config)
    return result.data
  }
  catch(error) {
    return {success: false, message: error}
  }
}