import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchOrderForm, Order } from '../components'
import { fetchOrder } from '../utils/requestHelper'

const Orders = () => {
  const {t} = useTranslation()
  const params = useParams()

  const [ order, setOrder ] = useState()
  const [ data, setData ] = useState({orderId: null, mail: null})
  const [ error, setError ] = useState(false)

  useEffect(() => {
    const { orderId } = params
    if(orderId)
      setData(prevData=>({
        ...prevData,
        orderId: orderId
      }))
  },[params.orderId])

  const validateForm = () => {
    const errors = {
      mailError: null,
      orderIdError: null,
      verificationState: true
    }

    if(!data.orderId){
      errors.orderIdError = t('error-empty-orderId')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSubmit = () => {
    if(validateForm())
      fetchData(data.orderId, data.mail)
  }

  const fetchData = async (orderId, mail) => {
    var response = await fetchOrder(orderId, mail)
    if(response.success === true) {
      setOrder(response.data)
      setError(false)
    }
    else
      setError(true)
  }

  return (
    <main>
      {!order && <SearchOrderForm
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
        orderId={data.orderId}
        mail={data.mail}
        error={error}
      />}
      <Order
        order={order}
      />
    </main>
  );
}

export default Orders;