import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Checkout, FragmentFrame } from './components'
import { Conditions, Orders, Main, Places, About, Policy, Corporation, Company, Environment, Contact, Mobile, Associate, MainStore, Career, Job, Application, JobEducation, JobWork, Employees, Products } from './pages'
import Layout from './layout'

const Router = () => {
  return (
    <Routes>
      <Route path="/application/:jobId" element={<Application />} ></Route>
      <Route path="/api/payout/orders/:orderId/approve" element={<FragmentFrame />} ></Route>
      <Route path="/api/payout/orders/:orderId/cancel" element={<FragmentFrame />} ></Route>
      <Route path="/places" element={
        <Layout>
          <Places />
        </Layout>} 
      />
      <Route path="/products" element={
        <Layout>
          <Products />
        </Layout>}
      />
      <Route path="/about" element={
        <Layout>
          <About />
        </Layout>}
      />
      <Route path="/policy" element={
        <Layout>
          <Policy />
        </Layout>}
      />
      <Route path="/conditions" element={
        <Layout>
          <Conditions />
        </Layout>}
      />
      <Route path="/corporation" element={
        <Layout>
          <Corporation />
        </Layout>}
      />
      <Route path="/employees" element={
        <Layout>
          <Employees />
        </Layout>} 
      />
      <Route path="/company" element={
        <Layout>
          <Company />
        </Layout>} 
      />
      <Route path="/contact" element={
        <Layout>
          <Contact />
        </Layout>}
      />
      <Route path="/environment" element={
        <Layout>
          <Environment />
        </Layout>} 
      />
      <Route path="/mobile" element={
        <Layout>
          <Mobile />
        </Layout>}
      />
      <Route path="/associate" element={
        <Layout>
          <Associate />
        </Layout>} 
      />
      <Route path="/mystore/:storeId?" element={
        <Layout>
          <MainStore />
        </Layout>}
      />
      <Route path="/education" element={
        <Layout>
          <JobEducation />
        </Layout>} 
      />
      <Route path="/work" element={
        <Layout>
          <JobWork />
        </Layout>}
      />
      <Route exact path="/jobs" element={
        <Layout>
          <Career />
        </Layout>} 
      />
      <Route path="/jobs/:jobId" element={
        <Layout>
          <Job />
        </Layout>} 
      />
      <Route path="/selfservice/orders/:orderId?" element={
        <Layout>
          <Orders />
        </Layout>} 
      />
      <Route path="/checkout" element={
        <Layout>
          <Checkout />
        </Layout>} 
      />
      <Route exact path="/" element={
        <Layout>
          <Main />
        </Layout>} 
      />
    </Routes>
  )
}

export default Router