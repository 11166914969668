import React from 'react'
import { Image } from 'semantic-ui-react'

import card from './images/WhatsApp.png'

import './whatsApp.scss'

const WhatsApp = (props) => {

  return(
      <a
        href={`https://wa.me/${props.phone}`}
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={card} size='small' />
      </a>
  );
}

export default WhatsApp;