import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Form, Transition, Grid, Message } from 'semantic-ui-react';
import { InputField } from '..';
import { DrawButton } from '../button';

const ApplicationPolicy = (props) => {
  const {t} = useTranslation()

  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Header className={'headline-regular'} as='h1'>{t('headline-application_policy')}</Header>
        <Form style={{padding: 15}}>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnToggleIAgree}
              id="iAgree"
              type="checkbox"
              checked={props.iAgree}
              label={props.iAgreeError ? <label style={{color: '#7F1019'}}>{t('input-label-policy')}</label> : <label>{t('input-label-policy')}</label>}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnToggleTalentPool}
              id="iPool"
              type="checkbox"
              label={t('input-label-pool')}
              required
            />
          </Form.Group>
        </Form>
        {props.verificationState === false && <Message negative>
          <Message.Header>{t('error-headline-form')}</Message.Header>
          <p>{t('error-content-form')}</p>
        </Message>}
        <Grid columns='equal'>
          <Grid.Column>
            <DrawButton
              name={t(`button-back`)}
              onClick={props.handleOnBack}
            />
          </Grid.Column>
          <Grid.Column>
            <DrawButton
              name={t(`button-application_send`)}
              onClick={props.handleOnSubmit}
            />
          </Grid.Column>
        </Grid>
      </section>
    </Transition>
  )
}

export default ApplicationPolicy