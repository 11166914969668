import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message, Icon } from 'semantic-ui-react';
import { removeNotification } from "../../redux/actions";

const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const style = {
    container: {
      zIndex: 100,
      position: 'fixed',
      right: 0,
      top: 0,
      margin: 20
    },
    message: {
      animation:' flyin 1.2s ease forwards',
      opacity: 0,
      transform: 'scale(2)',
      background: 'rgba(0, 0, 0, .7)',
      color: 'rgba(255,255,255,.9)',
    }
  }

  if(!notification)
    return null

  return(
    <div style={style.container}>
      {notification.map((element, index) =>{
        setTimeout(()=>{
          dispatch(removeNotification(element.id))
        }, 5000)
        return(
          <Message
            key={`notification-message-#${element.id}`}
            onDismiss={()=>dispatch(removeNotification(element.id))} 
            icon
            size='tiny'
            style={style.message}
          >
            <Icon name={element.icon ? element.icon : 'info'} />
            <Message.Content>
              <Message.Header>{element.header}</Message.Header>
              {element.content}
            </Message.Content>
          </Message>
        )
      })}
    </div>
  )
}

export default Notification