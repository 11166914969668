import React, { useEffect, useState } from "react";

const CustomCheckbox = ({label, id, headline, onChange, selected }) => {

  const [ checked, setChecked ] = useState()

  const handleOnChange = () => {
    setChecked(!checked)
    onChange({label: headline, value: label, checked: !checked})
  };

  useEffect(()=>{
    if(selected) {
      if(selected.filter(x => x === label).length > 0)
        setChecked(true)
      else
        setChecked(false)
    }
  },[selected])

  const style = {
    inputGroup: {
      backgroundColor: checked ? '#c39d63' : '#fff',
      display: 'block',
      margin: '10px 0',
      position: 'relative'
    },
    label: {
      padding: '12px 30px',
      width: '100%',
      display: 'block',
      textAlign: 'left',
      color: checked ? '#fff' : '#3C454C',
      border: 'solid 1px',
      borderColor: checked ? '#fff' : '#c39d63' ,
      cursor: 'pointer',
      position: 'relative',
      zIndex: 2,
      transition: 'color 200ms ease-in',
      overflow: 'hidden',
      '&::before': {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        content: '',
        backgroundColor: '#5562eb',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%) scale3d(1, 1, 1)',
        transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
        opacity: 0,
        zIndex: -1
      },
      '&::after': {
        width: '32px',
        height: '32px',
        content: '',
        border: '2px solid #D1D7DC',
        backgroundColor: checked ? '#54E0C7' : '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '2px 3px',
        borderRadius: '50%',
        borderColor: checked ? '#54E0C7' : '#fff',
        zIndex: 2,
        position: 'absolute',
        right: '30px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        transition: 'all 200ms ease-in',
      }
    },
    input: {
      width: 32,
      height: 32,
      order: 1,
      zIndex: 2,
      position: 'absolute',
      right: 30,
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      visibility: 'hidden'
    }
  }

  return(
    <div style={style.inputGroup} >
      <input onChange={handleOnChange} style={style.input} id={id} checked={checked} name={label} type="checkbox"/>
      <label style={style.label} htmlFor={id}>{label}</label>
    </div>
  )
}

export default CustomCheckbox