import React, { useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/actions/";
import { Menu, Sidebar, Image, Divider, Transition } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from "react-router-dom";
import { HamburgerToggle, CartIcon } from '../'

import Logo from '../../images/logo.svg'

import image01 from '../../images/mehrHeimat.png'
import image02 from '../../images/mehrLiebe.png'
import image03 from '../../images/mehrLaecheln.png'
import image04 from '../../images/mehrZeit.png'

import './header.scss'

const Header = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sidebar = useSelector((state) => state.sidebar)
  const headerMenu = useSelector((state) => state.headerMenu)
  const [size, setSize] = useState([0, 0]);
  const images = [image01, image02, image03, image04]

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleOnClickImage = () => {
    navigate('/')
  }

  const renderMenu = () => {
    var items = []
    headerMenu.sort((a,b) => a.position - b.position);
    headerMenu.forEach(element => {
      items.push(<Menu.Item
        key={`header-menu-${element.name}`}
        name={t(`header-menu-${element.name}`)}
        as={Link}
        to={`/${element.section}#${element.hash}`}
        onClick={()=>dispatch(toggleSidebar(false))}
      >
        {t(`header-menu-${element.name}`)}
      </Menu.Item>)
    });
    return items
  }

  const renderMobileMenu = () => {
    var items = []
    headerMenu.sort((a,b) => a.position - b.position);
    headerMenu.forEach(element => {
      items.push(
        <h3 style={{fontFamily: 'Avenir Next Codensed Regular'}} key={`header-headline-${element.name}`}>
          <Menu.Item
            key={`header-menu-${element.name}`}
            name={t(`header-menu-${element.name}`)}
            as={Link}
            to={`/${element.section}#${element.hash}`}
            onClick={()=>dispatch(toggleSidebar(false))}
            className={'header-text-left'}
          >
            {t(`header-menu-${element.name}`)}
          </Menu.Item>
        </h3>
      )
    });
    return items
  }

  return(
    <header className={'global-header'}>
      {size < 1000 ? 
        <Menu text size='huge' pointing className={'header-nav'}>
          <Image src={Logo} size='small' style={{margin: 20}} alt="header logo" onClick={handleOnClickImage} />
          <Sidebar
              as={Menu}
              animation='slide along'
              icon='labeled'
              vertical
              direction='right'
              visible={sidebar}
              width='wide'
              style={{'padding': '150px 0 0 30px', width: '100%'}}
            >
            {renderMobileMenu()}
            <Divider />
            <h3>
              <CartIcon small />
            </h3>
            <div style={{position: 'absolute', bottom: '25px', right: '25px'}}>
              <Transition visible={sidebar} animation='zoom' duration={1000}>
                <Image size='small' src={images[Math.floor(Math.random()*images.length)]} />
              </Transition>
            </div>
          </Sidebar>
          <HamburgerToggle />
        </Menu> :
        <Menu text size='huge' inverted pointing >
          <Image src={Logo} size='medium' alt="header logo" onClick={handleOnClickImage}/>
          {renderMenu()}
          <Menu.Menu position='right'>
            <CartIcon />
          </Menu.Menu>
        </Menu>
      }
    </header>
  );
}

export default Header;