import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Header, Grid, Image, Segment, List } from 'semantic-ui-react'
import { IconGrid } from '..'
import { DrawButton, MailButton, PhoneButton } from '../button'
import { Helmet } from "react-helmet"
import { articleStructuredData } from "./structured-data"

import jobImage_01 from './images/haeussler-job-01.webp'
import jobImage_02 from './images/haeussler-job-02.webp'
import jobImage_03 from './images/haeussler-job-03.webp'


const CareerOffer = (props) => {
  const {t} = useTranslation();

  const addedValues = [
    {
      content: t('job-addedValues-01'),
      image: 'trophy-svg.svg',
    },
    {
      content: t('job-addedValues-02'),
      image: 'chart-svg.svg',
    },
    {
      content: t('job-addedValues-03'),
      image: 'globe-svg.svg',
    },
    {
      content: t('job-addedValues-04'),
      image: 'calendar-svg.svg',
    },
    {
      content: t('job-addedValues-05'),
      image: 'money-svg.svg',
    },
    {
      content: t('job-addedValues-06'),
      image: 'cake-svg.svg',
    },
    {
      content: t('job-addedValues-07'),
      image: 'bike-svg.svg',
    },
    {
      content: t('job-addedValues-08'),
      image: 'heart-svg.svg',
    },
  ]

  return(
    <section key={props.id} id={`#${props.id}`}>
      {props.isActive === 1 && <Helmet>
        <script type="application/ld+json">
          {articleStructuredData({
            title: props.name,
            description: props.description,
            activities: props.activities,
            skills: props.skills,
            location: props.location.location,
            streetAddress: props.location.street_address,
            postalCode: props.location.postal_code,
            identifier: props.identifier,
            subscription: props.subscription,
            createdAt: props.modifiedAt
          })}
        </script>
      </Helmet>}
      <Container className='very'>
        <Grid>
          <Grid.Row stretched columns={3} only='tablet computer'>
            <Grid.Column>
              <Image rounded src={jobImage_01} size='large' />
            </Grid.Column>
            <Grid.Column>
              <Image rounded src={jobImage_02} size='large' />
            </Grid.Column>
            <Grid.Column>
              <Image rounded src={jobImage_03} size='large' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container>
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column width={11}>
              <Header className={'headline-regular'} as='h1'>{props.name}</Header>
              <p>{props.description}</p>
              {props.activities.length > 0 && <Header className={'headline-regular'} as='h2'>{t(`haedline-tasks`)}</Header>}
              <List>
                {props.activities && props.activities.map((e)=>(
                  <List.Item key={e.id}>
                    <List.Icon name='chevron right' />
                    <List.Content>{e.name}</List.Content>
                  </List.Item>
                ))}
              </List>
              {props.skills.length > 0  && <Header className={'headline-regular'} as='h2'>{t(`haedline-skills`)}</Header>}
              <List>
                {props.skills && props.skills.map((e)=>(
                  <List.Item key={e.id}>
                    <List.Icon name='chevron right' />
                    <List.Content>{e.name}</List.Content>
                  </List.Item>
                ))}
              </List>
              <Header className={'headline-regular'} as='h2'>{t(`headline-career-portal-01`)}</Header>
              <p>
                {t(`content-career-01`)}
              </p>
              <Header className={'headline-regular'} as='h2'>{t(`headline-career-portal-02`)}</Header>
              <p>
                {t(`content-career-02`)}
              </p>
              <IconGrid 
                icons={addedValues}
                columns={3}
                reveal={true}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment placeholder>
                {props.isActive === 1 && <DrawButton
                  name={t(`button-application`)}
                  onClick={()=>props.onSelect(props.jobId)}
                />}
                <Grid textAlign='left' columns={2} style={{marginTop: '25px'}}>
                  <Grid.Row>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3'>
                        {t(`haedline-job_Id`)}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontWeight: '300'}} as='h3'>
                        {props.identifier}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3'>
                        {t(`haedline-department`)}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontWeight: '300'}} as='h3'>
                        {props.department}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3'>
                        {t(`haedline-location`)}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontWeight: '300'}} as='h3'>
                        {props.location && props.location.location}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3'>
                        {t(`haedline-entry_level`)}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontWeight: '300'}} as='h3'>
                        {props.entryLevel}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3'>
                        {t(`haedline-subscription`)}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontWeight: '300'}} as='h3'>
                        {props.subscription}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3' dividing>
                {t(`haedline-location`)}
              </Header>
              <p>
                <span>{props.location && props.location.name}</span><br />
                <span>{props.location && props.location.street_address}</span><br />
                <span>{props.location && props.location.postal_code}, {props.location && props.location.location}</span>
              </p>
              <Header style={{fontFamily: 'Avenir Next Codensed Ultralight'}} as='h3' dividing>
                {t(`haedline-contact`)}
              </Header>
              <p>
                {props.organizer && props.organizer ? `${props.organizer.givenname} ${props.organizer.surname}` : `${props.location.name}`}
              </p>
              <span>
                <MailButton
                  mail={props.organizer && props.organizer.mail ? props.organizer.mail : props.location.mail}
                />
              </span>
              <span>
                <PhoneButton
                  phone={props.organizer && props.organizer.phone ? props.organizer.phone : props.location.phone}
                />
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {props.isActive === 1 && <DrawButton
              name={t(`button-application`)}
              onClick={()=>props.onSelect(props.jobId)}
            />}
          </Grid.Row>
        </Grid>
      </Container>
    </section>
  );
}

export default CareerOffer