import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, SnackCard } from '../components'
import { Header, Container } from 'semantic-ui-react'
import { ProductList } from '../components'
import { ProductFilter } from '../components/product'

const Products = () => {
  const {t} = useTranslation();
  const [ activeItem, setActiveItem ] = useState()
  const [ allergenFilter, setAllergenFilter ] = useState([])

  const handleOnEditAllergenFilter = (e) => {
    var arr = []
    var value = e.target.value
    var checked = e.target.checked
    if(checked === true) {
      arr = [].concat(allergenFilter)
      arr.push(value)
    } 
    else {
      arr = allergenFilter.filter(item => item !== value)
    }
    setAllergenFilter(arr)
  }

  return (
    <main>
      <section key={'products-home'} id={`#home`}>
        <Container>
          <Header style={{fontFamily: 'Avenir Next Codensed Ultralight', fontSize: '6rem', fontWeight: '300'}} as='h1'>{t(`headline-products_home`)}</Header>
          <p>
            {t(`content-products_home`)}
          </p>
        </Container>
      </section>
      <ProductFilter
        onClickItem={setActiveItem}
        onSelectAllergen={handleOnEditAllergenFilter}
        activeItem={activeItem}
        allergenFilter={allergenFilter}
      />
      <ProductList
        id={'product-list'}
        activeItem={activeItem}
        allergenFilter={allergenFilter}
      />
      <SnackCard style={{background: 'transparent'}} />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Products;