import React from 'react'
import './buttons.scss'

const MailButton = (props) => {

  return(
    <a 
      href={`mailto:${props.mail}`} 
      target="blank" 
      className="btn-styled-icon spanish-white icon-mail"
      style={{paddingLeft: '50px'}}
    >
			{`${props.mail}`}
    </a>
  );
}

export default MailButton;