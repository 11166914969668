import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Item, Button, Icon } from 'semantic-ui-react'

const CartItem = (props) => {
  const {t} = useTranslation()
  return(
    <Item>
      <Item.Content verticalAlign='middle'>
        <Item.Header style={{fontFamily: 'Avenir Next Codensed Regular'}}>{props.name}</Item.Header>
        <Item.Meta>
          {props.isAvailable === false && <Item.Header style={{fontFamily: 'Avenir Next Codensed Regular', color: 'var(--main-font-color-error)'}}>{t(`error-notAvailable`)}</Item.Header>}
          <span style={{fontFamily: 'Bradley Hand'}} >{props.description}</span>
          {props.notes && <Item.Header style={{fontFamily: 'Avenir Next Codensed Regular'}}>{props.notes}</Item.Header>}
        </Item.Meta>
        <Button.Group>
          <Button icon onClick={()=>props.onMinus(props.productId)}>
            <Icon name='minus' /> 
          </Button>
          <Button.Or text={props.qty} />
          <Button icon onClick={()=>props.onPlus(props.productId)}>
            <Icon name='plus' /> 
          </Button>
        </Button.Group>
        <Item.Extra className='item-total'>
            <Header floated='right' style={{fontFamily: 'Bradley Hand', fontSize: '1.3rem', fontWeight: '600'}} as='h3'>{props.total} {t(`currency-${props.currency}`)}</Header>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}

export default CartItem;