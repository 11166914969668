import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Form, Transition, Grid, Progress } from 'semantic-ui-react';
import { InputField } from '..';
import CustomCheckbox from '../checkbox';
import { DrawButton } from '../button';

const ApplicationInterview = ({interview, handleOnBack, handleOnSubmit, show, data, progress }) => {
  const {t} = useTranslation()
  const [ selected, setSelected ] = useState([])

  useEffect(()=>{
    setSelected([])
  },[interview])

  const onChange = (e) => {
    if(interview.type === 'text')
      setSelected(e.target.value)
    if(interview.type === 'select'){
      if(e.checked === true){
        setSelected([e.value])
      }
      else
        setSelected([])
    }
    if(interview.type === 'select-multi'){
      if(e.checked === true){
        let newArray = selected
        newArray.push(e.value)
        setSelected(newArray)
      }
      else{
        let newArray = selected.filter(x => x !== e.value)
        setSelected(newArray)
      }
    }
  }

  const onSubmit = () => {
    handleOnSubmit({interviewId: interview.id, name: interview.headline, notes: selected})
  }

  return(
    <Transition visible={show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Header className={'headline-regular'} as='h1'>{interview.headline}</Header>
        <p>
          {interview.content}
        </p>
        <Form style={{padding: 15}}>
          {(interview.type === 'select' || interview.type === 'select-multi') && 
          <div>
            {interview.defaultValue.split(',').map((x, index) => (
              <CustomCheckbox
                onChange={onChange}
                key={`interview-label${index}`}
                selected={selected}
                id={x}
                label={x}
                headline={interview.headline}
              />
            ))}
          </div>
          }
          {interview.type === 'text' && <InputField 
              handleOnChange={onChange}
              id={interview.headline}
              type="textarea"
              label={t('input-label-interview')}
              rows={10}
            />
          }
        </Form>
        <Grid columns='equal'>
          <Grid.Column>
            <DrawButton
              name={t(`button-back`)}
              onClick={handleOnBack}
            />
          </Grid.Column>
          <Grid.Column>
            <DrawButton
              name={t(`button-next`)}
              onClick={onSubmit}
            />
          </Grid.Column>
        </Grid>
      </section>
    </Transition>
)
}

export default ApplicationInterview