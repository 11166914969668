import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Applications } from '../components/application'
import { Progress } from 'semantic-ui-react'
import { fetchJob, uploadApplication, uploadApplicationAttachment } from '../utils/requestHelper'

import background from '../images/hauessler-application-background.webp'

const Application = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [ selectedJob, setSelectedJob ] = useState(null)
  const [ selectedQuestion, setSelectedQuestion ] = useState(-1)
  const [ loading, setLoading ] = useState(false)
  const [ show, setShow ] = useState(true)
  const [ step, setStep ] = useState(0)
  const [ progress, setProgress ] = useState({total: 0, active: 0})
  const [ data, setData ] = useState({
    givenname: null,
    surname: null,
    phone: null,
    mail: null,
    streetAddress: null,
    postalCode: null,
    location: null,
    interview: null,
    talentPool: null,
    attachments: [],
    attributes: []
  })

  const style = {
    background : {
      background: `url(${background})`,
      backgroundSize: 'cover', 
      backgroundPosition: '50%'
    },
    center : {
      "textAlign" : "center"
    },
    h100: {
      minHeight:'100vh',
      display: 'flex',
      flexWrap: 'wrap'
    },
    hero : {
      width: "100%",
      backgroundSize: 'cover',
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      position: "relative",
      backgroundImage: "linear-gradient(white 13%, white 13%, transparent 100%)"
    },
    progress: {
      margin: '6vw'
    },
    conatiner: {
      maxWidth: '85%',
      margin: 'auto',
      background: 'rgba(255,255,255, .6)'
    }
  }

  useEffect(() => {
    const { jobId } = params
    async function fetchJobData(id) {
      let response = await fetchJob(id)
      if(response.success === true)
        setSelectedJob(response.data)
      else
        navigate(`/jobs`)
    }
    if(jobId)
      fetchJobData(jobId)
    else
      navigate(`/jobs`)
  },[params]);

  useEffect(() => {
    if(selectedJob && selectedJob.interview.length > 0)
      setProgress((prevData)=>({
        ...prevData,
        total: selectedJob.interview.length + 4
      }))
  },[selectedJob])

  const onClickStart = () => {
    setProgress((prevData)=>({
      ...prevData,
      active: progress.active + 1
    }))
    if(selectedJob.interview.length > 0) {
      setShow(false)
      setTimeout(function (){
        setSelectedQuestion(0)
        setStep(1)
        setShow(true)
      }, 1000);
    }
    else {
      setShow(false)
      setTimeout(function (){
        setStep(2)
        setShow(true)
      }, 1000);
    }
  }

  const onClickNextQuestion = (value) => {
    let array = data.attributes
    let filteredArrayIndex = array.findIndex(x => x.interviewId === value.interviewId)
    if(filteredArrayIndex > -1)
      array[filteredArrayIndex] = value
    else
      array.push(value)

    setData(prevData => ({
      ...prevData,
      attributes: array
    }))
    setProgress((prevData)=>({
      ...prevData,
      active: progress.active + 1
    }))
    if(selectedQuestion < (selectedJob.interview.length -1)) {
      setShow(false)
      setTimeout(function (){
        setSelectedQuestion(selectedQuestion + 1)
        setStep(1)
        setShow(true)
      }, 1000);
    }
    else {
      setShow(false)
      setTimeout(function (){
        setStep(2)
        setShow(true)
      }, 1000);
    }
  }

  const onClickPrevQuestion = () => {
    if(selectedQuestion > 0) {
      setShow(false)
      setTimeout(function (){
        setSelectedQuestion(selectedQuestion - 1)
        setProgress((prevData)=>({
          ...prevData,
          active: progress.active - 1
        }))
        setStep(1)
        setShow(true)
      }, 1000);
    }
    else {
      setShow(false)
      setTimeout(function (){
        setStep(0)
        setProgress((prevData)=>({
          ...prevData,
          active: 0
        }))
        setShow(true)
      }, 1000);
    }
  }

  const onClickNext = () => {
    if(validateForm()) {
      setShow(false)
      setTimeout(function (){
        setStep(step + 1)
        setProgress((prevData)=>({
          ...prevData,
          active: progress.active + 1
        }))
        setShow(true)
      }, 1000);
    }
  }

  const onClickBack = () => {
    if(step === 2) {
      setShow(false)
      setTimeout(function (){
        if(step !== 0) {
          setProgress((prevData)=>({
            ...prevData,
            active: 0
          }))
          setStep(0)
        }
        setShow(true)
      }, 1000);
    }
    else {
      setShow(false)
      setTimeout(function (){
        if(step !== 0) {
          setProgress((prevData)=>({
            ...prevData,
            active: progress.active - 1
          }))
          setStep(step - 1)
        }
        setShow(true)
      }, 1000);
    }
  }

  const handleOnFileChange = (e) => {
    if(!e.target.files[0])
      return
    if(e.target.files[0].size > 5300000){
      alert("File is too big!");
      return
    };
    let array = data.attachments
    array.push(e.target.files[0])
    setData(prevData => ({
      ...prevData,
      attachments: array
    }))
  };

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnToggleIAgree = () => {
    setData(prevData => ({
        ...prevData,
        iAgree: !data.iAgree
    }))
  }

  const handleOnToggleTalentPool = () => {
    setData(prevData => ({
        ...prevData,
        talentPool: !data.iAgree
    }))
  }

  const validateForm = () => {
    const errors = {
      mailError: null,
      givennameError: null,
      surnameError: null,
      streetAddressError: null,
      postalCodeError: null,
      locationError: null,
      verificationState: true
    }
    if(step === 2) {
      if(!data.givenname){
        errors.givennameError = t('error-empty-givenname')
        errors.verificationState = false
      }
      if(!data.surname){
        errors.surnameError = t('error-empty-surname')
        errors.verificationState = false
      }
      if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
        errors.mailError = t('error-empty-mail')
        errors.verificationState = false
      }
    }
    if(step === 3) {
      if(!data.streetAddress){
        errors.streetAddressError = t('error-empty-streetAddress')
        errors.verificationState = false
      }
      if(!data.postalCode){
        errors.postalCodeError = t('error-empty-postalCode')
        errors.verificationState = false
      }
      if(!data.location){
        errors.locationError = t('error-empty-location')
        errors.verificationState = false
      }
    }
    if(step === 5) {
      if(!data.iAgree){
        errors.iAgreeError = true
        errors.verificationState = false
      }
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnSubmit = async () => {
    if(validateForm() && loading === false){
      setLoading(true)
      var result = await uploadApplication(selectedJob.jobId, { 
        givenname : data.givenname, 
        surname : data.surname,
        phone : data.phone, 
        mail : data.mail, 
        streetAddress : data.streetAddress, 
        postalCode : data.postalCode, 
        location : data.location,
        interview: data.interview,
        talentPool : data.talentPool, 
        attributes : data.attributes.map(element => (
          { name: element.name, notes: element.notes.toString()}
        ))
      })
      if(result.success === true) {
        let applicationId = result.data.applicationId
        for (const iterator of data.attachments) {
          let result = await uploadApplicationAttachment(selectedJob.jobId,applicationId,iterator)
        }
        setStep(9999)
      }
      setLoading(false)
    }
  }

  return (
    <main style={style.background}>
      <div style={style.h100}>
        <div style={style.hero}>
          {(step !== 0 && step !== 9999) && <Progress percent={((100 * progress.active) / progress.total).toFixed(2)} progress style={style.progress}/>}
          <div style={style.conatiner}>
            {selectedJob && <Applications
              step={step}
              show={show}
              data={data}
              job={selectedJob}
              question={selectedQuestion}
              handleOnFileChange={handleOnFileChange}
              handleOnInputChange={handleOnChange}
              handleOnToggleIAgree={handleOnToggleIAgree}
              handleOnToggleTalentPool={handleOnToggleTalentPool}
              handleOnSubmit={handleOnSubmit}
              onClickStart={onClickStart}
              onClickNext={onClickNext}
              onClickBack={onClickBack}
              onClickNextQuestion={onClickNextQuestion}
              onClickPrevQuestion={onClickPrevQuestion}
            />}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Application;