import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, List, Button, Transition, Icon, Form, Grid } from 'semantic-ui-react';
import { DrawButton } from '../button';

const ApplicationUpload = (props) => {
  const {t} = useTranslation()
  const inputFile = useRef(null)

  const handleOnAddFile = () => {
    inputFile.current.click();
  };

  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Header className={'headline-regular'} as='h1'>{t('haedline-personal_attachments')}</Header>
        <p>
          {t('content-personal_attachments')}
        </p>
        <Form style={{margin: 30}}>
          <List>
          {props.attachments.map((e, index)=> (
            <List.Item key={`attachment-item-#${index}`}>
              <List.Content>
                <List.Header><List.Icon name='file' />{e.name}</List.Header>
              </List.Content>
            </List.Item>
          ))}
          </List>
          <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={props.handleOnFileChange} accept=".pdf,.docx,.doc"/>
          <Button animated='vertical' onClick={handleOnAddFile}>
            <Button.Content hidden>Upload</Button.Content>
            <Button.Content visible>
              <Icon name='upload' />
            </Button.Content>
          </Button>
        </Form>
        <Grid columns='equal'>
          <Grid.Column>
            <DrawButton
              name={t(`button-back`)}
              onClick={props.handleOnBack}
            />
          </Grid.Column>
          <Grid.Column>
            <DrawButton
              name={t(`button-next`)}
              onClick={props.handleOnSubmit}
            />
          </Grid.Column>
        </Grid>
      </section>
    </Transition>
  )
}

export default ApplicationUpload