import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Header } from 'semantic-ui-react'
import { Slider } from '../'

import slide_01 from '../../images/slider/Backhaus-Häussler-27.webp'
import slide_02 from '../../images/slider/Backhaus-Häussler-28.webp'
import slide_03 from '../../images/slider/Backhaus-Häussler-33.webp'
import slide_04 from '../../images/slider/Backhaus-Häussler-34.webp'
import slide_05 from '../../images/slider/Backhaus-Häussler-57.webp'
import slide_06 from '../../images/slider/Backhaus-Häussler-49.webp'

const Home = (props) => {
  const {t} = useTranslation()

  const slideData = [
    {
      index: 0,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Shop now',
      src: slide_01
    },
    {
      index: 1,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Book travel',
      src: slide_02
    },
    {
      index: 2,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Listen',
      src: slide_03
    },
    {
      index: 3,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_04
    },
    {
      index: 4,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_05
    },
    {
      index: 5,
      headline: 'Backhaus Häussler - Wir sind Allgäu Bäcker',
      button: 'Get Focused',
      src: slide_06
    }
  ]

  return(<>
    <section key={props.id} id={`#${props.id}`}>
      <Container>
        <div style={{maxWidth: 600, margin: 'auto'}}>
          <Header className={'headline-regular'} as='h1'>{t(`headline-home`)}</Header>
          <p>
            {t(`content-home_01`)}
          </p>
          <p>
            {t(`content-home_02`)}
          </p>
        </div>
      </Container>
    </section>
    <section key={props.id} id={`#$home-slider`}>
      <Container>
        <Slider auto heading={t(`headline-slider-home`)} slides={slideData}/>
      </Container>
    </section>
    </>
  );
}

export default Home;