import React from 'react'
import { Image, Container } from 'semantic-ui-react'

import partners from '../../config/dev.partners.json'
import map_webp from './images/allgaeu_karte.webp'

const PartnerMap = (props) => {

  const images = require.context('./images', true);
  const getPartnerLogo = (e) => {
    let imgsrc = images(`./${e}`)
    return imgsrc
  }

  const styles = {
    container: {
      background: `url(${map_webp})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      position: 'relative'
    },
    content: {
      position: 'absolute',
      zIndex: 100
    }
  }

  var type = 1
  var radius = '26vw'
  var radius2 = '13vw'
  var start = -90
  var numberOfElements = (type === 1) ?  (partners.length) : (partners.length) - 1
  var slice = 360 * type / numberOfElements;

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container key={props.id} id={`#${props.id}`} >
        <div style={styles.container}>
          {partners.map((element, index) =>{
            let rotate = slice * index + start
            let rotateReverse = rotate * -1
            let r = index % 2 === 0 ? radius : radius2
            return (
              <div style={{top: '40%', left: '40%', transform: `rotate(${rotate}deg) translate(${r}) rotate(${rotateReverse}deg)` , position: 'absolute', zIndex: (1 + index), transition: 'all 2s linear' }}>
                <Image src={getPartnerLogo(element.image)} style={{width: '8vw'}} />
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  );
}

export default PartnerMap;