import React from 'react'
import { Container, Header, Image, Embed } from 'semantic-ui-react'
import { DrawButton } from '../button';

const ArticleHorizontalVideo = (props) => {

  return(
    <section key={props.id} id={`#${props.id}`} >
      <Container>
        <article className='article horizontal img-first'>
          <div className='article__txt' >
            <Header className={'headline-regular'} as='h1'>{props.headline}</Header>
            <Header className={'headline-bag'} as='h3'>{props.subline}</Header>
            {props.content && Array.isArray(props.content) ?
              props.content.map((element)=>(
                  <p>
                    {element}
                  </p>
              ))
            :
              <p>
                {props.content}
              </p>
            }
            {props.btnHandleOnClick && props.btnText && 
            <p>
              <DrawButton
                name={props.btnText}
                onClick={props.btnHandleOnClick}
              />
            </p>
            }
          </div>
          <div className='article__img' >
            <Embed
              className="img"
              style={{margin: 0, background: '#000'}}
              icon='right circle arrow'
              url={props.mp4}
            />
            <div className="img-stamp">
              <div className="square-wrapper">
                <div className="square square-transition">
                  {props.card && <Image alt={props.headline} size='tiny' src={props.card} style={{width: '300px'}}/>}
                </div>
              </div>
            </div>
          </div>
        </article>
      </Container>
    </section>
  );
} 

export default ArticleHorizontalVideo