import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Image, Header, Grid, List } from 'semantic-ui-react'
import { DrawButton } from '../button'

const ProductCard = (props) => {
  const {t} = useTranslation()
  const [ visibility, setVisibility ] = useState()
  const ref = useRef()

  useEffect(()=>{
    if(props.allergenes.split(',').some(v=> props.allergenFilter.indexOf(v) !== -1))
      setVisibility(false)
    else
      setVisibility(true)
  },[props])

  const style = {
    card: {
      boxShadow: 'none',
      width: props.activeCard === props.id ? '100%' : '',
      transition: 'width 0.3s ease-in-out 25ms',
      OTransition: 'width 0.3s ease-in-out 25ms',
      MozTransition: 'width 0.3s ease-in-out 25ms',
      WebkitTransition: 'width 0.3s ease-in-out 25ms',
    },
    content: {
      border: 'none',
      paddingLeft: 0
    },
    extra: {
      flexGrow: 0,
      textAlign: 'right'
    },
    image: {
      height: 250,
      width: 300,
      objectFit: 'cover',
      objectPosition: 'center',
      marginLeft: 0
    },
    spanContainer: {
      textAlign: 'center',
      margin: 30
    },
    span: {
      fontFamily: 'var(--main-font)',
      fontSize: '1.3rem',
      borderTop: '2px solid var(--body-font-color)',
      color: 'var(--body-font-color)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    }
  }

  const description = (string) => {
    if(props.activeCard === props.id)
      return string
    var maxLength = 150
    var trimmedString = string.substr(0, maxLength);
    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + ' ...'
  }

  const replaceText = (string) => {
    return string.replace(/,/g, ', ');
  }

  if(visibility === false)
    return null

  return(
    <Card key={`product-card-#${props.id}`} id={props.id} style={style.card} centered>
      <div id={`product-card-ref-#${props.id}`} ref={ref} style={{visibility: 'hidden'}}>scrollelement</div>
      <Image src={`${window._env_.REACT_APP_API_URL}/v1/images/products/${props.id}`} loading="lazy" centered style={style.image}/>
      <Card.Content style={style.content}>
      <Grid stackable divided columns='equal'>
        <Grid.Column>
          <Card.Header style={{ fontSize: '2rem'}}>{props.header}</Card.Header>
          <Card.Description style={{fontFamily: 'Bradley Hand', fontSize: '2rem'}}>
            {description(props.description)}
          </Card.Description>
        </Grid.Column>
        {props.activeCard === props.id && <Grid.Column>
          {props.ingredients && <div style={{marginBottom: 15}}>
            <Card.Header style={{ fontSize: '2rem'}}>{t('headline-ingredients')}</Card.Header>
            <Card.Description >
              {replaceText(props.ingredients)}
            </Card.Description>
          </div>}
          {props.allergenes && <div style={{marginBottom: 15}}>
            <Card.Header style={{marginTop:15, fontSize: '2rem'}}>{t('headline-allergenes')}</Card.Header>
            <Card.Description >
              {replaceText(props.allergenes)}
            </Card.Description>
          </div>}
        </Grid.Column>}
        {props.activeCard === props.id && <Grid.Column>
          {props.calorific && <div style={{marginBottom: 15}}>
            <Card.Header style={{ fontSize: '2rem'}}>{t('headline-calorific')}</Card.Header>
            <Card.Description >
              {props.calorific}
            </Card.Description>
          </div>}
          {props.nutrition && <div style={{marginBottom: 15}}>
            <Card.Header style={{fontSize: '2rem'}}>{t('headline-nutrition')}</Card.Header>
            <Card.Description >
              <List>
                {props.nutrition.map((element, index) =>(
                  <List.Item key={`product-nutrition-${props.id}-#${index}`}>
                    <List.Content floated='right' >{element.value}</List.Content>
                    <List.Content>{element.name}</List.Content>
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </div>}
        </Grid.Column>}
      </Grid>
      </Card.Content>
      <div style={style.spanContainer} onClick={props.activeCard === props.id ? props.showLess : ()=>props.showMore(props.id, ref)}>
        <span style={style.span}>{props.activeCard === props.id ? t('button-read_less') : t('button-read_more')}</span>
      </div>
      {props.ordering === 1 &&
      <div>
        <div style={style.extra}>
          <Header style={{fontFamily: 'Bradley Hand', fontSize: '2rem', fontWeight: '300'}} as='h3'>{props.price} {t(`currency-${props.currency}`)}</Header>  
        </div>
        <div style={style.extra}>
          <DrawButton
            name={t('button-to_cart')}
            fontSize='1.1rem'
            onClick={()=>props.addItem(props.id)}
          />  
        </div>
      </div>
      }
      <Card.Content extra />
    </Card>
  );
}

export default ProductCard;