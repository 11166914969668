import { NETWORK_START, NETWORK_STOP, NETWORK_ERROR, CATEGORIES_SET, PRODUCTS_SET } from "../constants/action-types";

import { fetchProductsCategories, fetchProducts } from '../../utils/requestHelper'

export function getCategories(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetchProductsCategories()
      if(result.success === true){
        dispatch({ type: CATEGORIES_SET, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function getProducts(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetchProducts()
      if(result.success === true){
        dispatch({ type: PRODUCTS_SET, result: result.data })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}