import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram } from '../components'

import { Article, ArticleHorizontal } from '../components/section'

import background from '../images/image-smile-haeussler.jpg'
import background2 from '../images/image-environment-haeussler.webp'
import background3 from '../images/recup.jpeg'
import stamp from '../images/mehrZeit.png'

const Environment = () => {
  const {t} = useTranslation()

  return (
    <main>
      <ArticleHorizontal
        id={'enduring'}
        headline={t(`haedline-enduring`)}
        subline={t(`sub-headline-enduring`)}
        content={[t(`content-enduring-01`),t(`content-enduring-02`),t(`content-enduring-03`),t(`content-enduring-04`)]}
        image={background2}
      />
      <Article
        id={'recup'}
        headline={t(`haedline-recup`)}
        subline={t(`sub-headline-recup`)}
        content={t(`content-recup`)}
        image={background3}
      />
      <ArticleHorizontal
        id={'newPaths'}
        headline={t(`haedline-newPaths`)}
        subline={t(`sub-headline-newPaths`)}
        content={[t(`content-newPaths-01`),t(`content-newPaths-02`)]}
        image={background}
        card={stamp}
      />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Environment;