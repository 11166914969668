import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Image, Container, Header } from 'semantic-ui-react'

import background from '../../images/BHMM_APP.webp'

import google from '../../images/google-play-badge.png'
import apple from '../../images/app-store-badge.svg'

const SnackCard = (props) => {
  const {t} = useTranslation()

  const style = {
    sectionDark : {
      padding: '30px 0',
      background: '#f0ebe1'
    },
    article: {
      flexDirection: 'row',
      display: 'flex,'
    }
  }

  return(
    <section style={props.style ? props.style : style.sectionDark} key={props.id} id={`#${props.id}`} >
      <Container fluid >
        <article className='article horizontal img-first'>
          <div className='article__img' >
            <Image src={background} />
          </div>
          <div className='article__txt' >
            <Header className={'headline-regular'} as='h1'>{t(`haedline-snackCard`)} <strong style={{fontWeight: 900}}>Häussler App</strong></Header>
            <Header className={'headline-bag'} as='h3'>{t(`sub-headline-snackCard`)}</Header>
            <p>
              {t(`content-snackCard`)}
            </p>
            <p>
              {t(`content-snackCard-app`)}
            </p>
            <Image.Group size='small'>
              <Image to={'https://play.google.com/store/apps/details?id=de.firstconcept.haeussler&hl=de&gl=US'} target='_blank' as={Link} src={google} />
              <Image to={'https://apps.apple.com/de/app/h%C3%A4ussler/id1565347806'} target='_blank' as={Link} src={apple} />
            </Image.Group>
          </div>
        </article>
      </Container>
    </section>
  );
} 

export default SnackCard;