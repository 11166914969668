import { CART_ADD, CART_CLEAR, CART_LOAD, CART_REMOVE, NETWORK_ERROR, NETWORK_START, NETWORK_STOP } from "../constants/action-types";

import { fetchCart } from '../../utils/requestHelper';

export function loadCart(){
  return async (dispatch) => {
    try {
      dispatch({ type: NETWORK_START });
      const result = await fetchCart()
      if(result.success === true){
        if(result.data.items)
          var qty = 0
          for (const iterator of result.data.items) {
            qty += iterator.qty
          }
          dispatch({ type: CART_LOAD, result: qty })
      }
      dispatch({ type: NETWORK_STOP });
    } catch (error) {
      dispatch({ type: NETWORK_STOP });
      dispatch({ type: NETWORK_ERROR, error });
    }
  };
}

export function addToCart(){
  return (dispatch) => {
    dispatch({type:CART_ADD})
  }
}

export function removeFromCart(){
  return (dispatch) => {
    dispatch({type:CART_REMOVE})
  }
}

export function clearCart(){
  return (dispatch) => {
    dispatch({type:CART_CLEAR})
  }
}