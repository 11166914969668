import React, { useState, useEffect } from 'react'
import { TransitionablePortal, Image, Container, Modal, Icon } from 'semantic-ui-react'
import { DrawButton } from '../button'
import MenuCardHeadline from './menuCardHeadline'
import MenuCardItem from './menuCardItem'
import MenuCardFooter from './menuCardFooter'

import { useDispatch, useSelector } from "react-redux";
import { toggleMenuCard, addToCart } from "../../redux/actions/";
import { addCartItem } from '../../utils/requestHelper'

import logo from '../../images/logo.svg'
import background from '../../images/black.jpg'

import './menuCard.scss'

const MenuCard = (props) => {
  const dispatch = useDispatch()
  const menuCard = useSelector((state) => state.menuCard)
  const products = useSelector((state) => state.products)
  const productsCategories = useSelector((state) => state.productsCategories)

  const [ filteredProducts, setFilteredProducts ] = useState()
  const [ selectedCategory, setSelectedCategory ] = useState()

  useEffect(() => {
    if(productsCategories)
      setSelectedCategory(productsCategories[0])
  },[menuCard])

  useEffect(() => {
    if(selectedCategory)
      handleOnSelectCategory(selectedCategory)
  },[selectedCategory])

  const handleOnSelectCategory = (filter) => {
    var new_obj_array = products.filter(function(obj) {
      if(filter.indexOf(obj.category) === -1)
        return false;
      else
        return true;
    })
    setFilteredProducts(new_obj_array)
  }

  const handleOnClickNext = () => {
    let newIndex = productsCategories.indexOf(selectedCategory)
    newIndex ++
    if(newIndex >= productsCategories.length)
      newIndex = 0
    setSelectedCategory(productsCategories[newIndex])
  }

  const handleOnClickBack = () => {
    let newIndex = productsCategories.indexOf(selectedCategory)
    newIndex --
    if(newIndex === -1)
      newIndex = productsCategories.length -1
    setSelectedCategory(productsCategories[newIndex])
  }

  const handleOnAddProduct = async (id) => {
    var result = await addCartItem(id)
    if(result.success === true)
      dispatch(addToCart())
  }

  return(
    <TransitionablePortal
      open={menuCard}
      transition={{ animation: 'zoom', duration: '600' }}
    >
      <Modal
        open={true}
        closeIcon={<Icon inverted color='grey' name='close' />}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={() => dispatch(toggleMenuCard())}
        style={{backgroundImage: `url(${background})`}}
      >
        <Container>
          <div className='menuCard logo'>
            <Image src={logo} size='small' style={{display: 'initial'}}/>
          </div>
          <MenuCardHeadline name={selectedCategory}/>
          {filteredProducts && filteredProducts.map((element, index) =>(
            <MenuCardItem
              key={element.id}
              id={element.id}
              name={element.name}
              description={element.description}
              additives={element.additives}
              price={element.price}
              currency={element.currency}
              regional={element.regional}
              ordering={element.ordering}
              biological={element.biological}
              addItem={handleOnAddProduct}
            />
          ))}
          <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
            <div style={{textAlign: 'left', flex: '1 1 0px'}}>
              <DrawButton
                name="zurück"
                onClick={handleOnClickBack}
              />  
            </div>
            <div style={{textAlign: 'right', flex: '1 1 0px'}}>
              <DrawButton
                name="vor"
                onClick={handleOnClickNext}
              />  
            </div>
          </div>
          <MenuCardFooter />
        </Container>
      </Modal>
    </TransitionablePortal>
  );
}

export default MenuCard;