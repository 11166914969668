import translation from '../locale/de/translate.json'

export function logoStructuredData({
  url,
  logo
  }) {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": url,
    "logo": logo
  });
}

export function faqStructuredData() {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": translation.translations['haedline-snackCard'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `<p>${translation.translations['content-snackCard']}</p><p>${translation.translations['content-snackCard-app']}</p>`
      }
    },
    {
      "@type": "Question",
      "name": translation.translations['panel-faq-question_1'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": translation.translations['panel-faq-answer_1']
      }
    },
    {
      "@type": "Question",
      "name": translation.translations['panel-faq-question_2'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": translation.translations['panel-faq-answer_2']
      }
    },
    {
      "@type": "Question",
      "name": translation.translations['panel-faq-question_3'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": translation.translations['panel-faq-answer_3']
      }
    },
    {
      "@type": "Question",
      "name": translation.translations['panel-faq-question_4'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": translation.translations['panel-faq-answer_4']
      }
    },
    {
      "@type": "Question",
      "name": translation.translations['panel-faq-question_5'],
      "acceptedAnswer": {
        "@type": "Answer",
        "text": translation.translations['panel-faq-answer_5']
      }
    }]
  });
}