import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, getCategories, getProducts, loadCart } from './redux/actions'
import { BrowserRouter } from 'react-router-dom'
import withClearCache from "./ClearCache"
import Router from './Router'

const ClearCacheComponent = withClearCache(MainApp)

function App(props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(startLoading())
    dispatch(getCategories())
    dispatch(getProducts())
    dispatch(loadCart())
    setTimeout(() => {
      dispatch(stopLoading())
    },1800);
  },[])

  return <ClearCacheComponent />
}

function MainApp(props) {
  return (
    <BrowserRouter >
      <Router />
    </BrowserRouter>
  )
}

export default App