import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const OpeningHours = (props) => {
  const {t} = useTranslation()
  if(!props.open || !props.close)
    return null

  var day = `${moment().day(props.open.day).format("dddd")}`
  let start = moment(props['open'].time, 'HHmm')
  let end = moment(props['close'].time, 'HHmm')

  return(
    <div>
      <span>{`${t(day)}: ${start.format('HH:mm')} - ${end.format('HH:mm')}`}</span>
    </div>
  )
}

export default OpeningHours;