import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Form, Transition, Grid } from 'semantic-ui-react';
import { InputField } from '..';
import { DrawButton } from '../button';

const ApplicationPersonal = (props) => {
  const {t} = useTranslation()
  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Header className={'headline-regular'} as='h1'>{t('headline-application_personal')}</Header>
        <p>
          {t('content-application_personal')}
        </p>
        <Form style={{padding: 15}}>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.givenname}
              valueError={props.givennameError}
              id="givenname"
              type="text"
              label={t('input-label-givenname')}
              autoComplete='given-name'
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.surname}
              valueError={props.surnameError}
              id="surname"
              type="text"
              label={t('input-label-surname')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.mail}
              valueError={props.mailError}
              id="mail"
              type="mail"
              label={t('input-label-mail')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.phone}
              id="phone"
              type="phone"
              label={t('input-label-phone')}
            />
          </Form.Group>
        </Form>
        <Grid columns='equal'>
          <Grid.Column>
            <DrawButton
              name={t(`button-back`)}
              onClick={props.handleOnBack}
            />
          </Grid.Column>
          <Grid.Column>
            <DrawButton
              name={t(`button-next`)}
              onClick={props.handleOnSubmit}
            />
          </Grid.Column>
        </Grid>
      </section>
    </Transition>
  )
}

export default ApplicationPersonal