import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { customArrayFilter } from '../../utils/filterHelper'
import { fetchLocations, fetchLocation, patchOrder, fetchReauthorizeOrder } from '../../utils/requestHelper'
import { Container, Grid, Header, Form, Item, Divider, Message } from 'semantic-ui-react'
import { InputField } from '../'
import { DrawButton } from '../button'
import OrderItem from './orderItem'

const Order = (props) => {
  const { order } = props

  const {t} = useTranslation()
  const [ loaded, setLoaded ] = useState(false)
  const [ send, setSend ] = useState(null)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ locations, setLocations ] = useState()
  const [ selectedLocation, setSelectedLocation ] = useState()
  const [ selectedLocationData, setSelectedLocationData ] = useState()
  const [ data, setData ] = useState({
    pickupStore: null,
    pickupDate: null,
    pickupTime: null,
    pickupStoreError: null,
    pickupDateError: null,
    pickupTimeError: null,
    notes:null,
    canEdit:false,
    canPay: false
  })

  const [ dateArray, setDateArray ] = useState([])
  const [ timeArray, setTimeArray ] = useState()

  useEffect(() => {
    const getLocationData = async(storeId) => {
      var result = await fetchLocation(storeId)
      if(result.success === true){
        setSelectedLocationData(result.data)
      }
    }
    if(selectedLocation){
      getLocationData(selectedLocation)
    }
  },[selectedLocation])

  useEffect(() => {
    if(selectedLocationData){
      getStoreDateValues()
    }
  },[selectedLocationData])

  useEffect(() => {
    if(order && loaded === false){
      getStoreTimeValues(order.pickupDate)
      setLoaded(true)
    }
  },[dateArray])

  useEffect(() => {
    const getLocations = async() => {
      let result = await fetchLocations(true)
      if(result.success === true){
        let filteredLocations = result.data.filter(x => x.pickup_store === 1)
        setLocations(filteredLocations)
        setSelectedLocation(order.pickupStore.id)
      }
    }
    if(order) {
      getLocations()
      if(order.orderState === 'purchased')
        setData(prevData=>({
          ...prevData,
          canEdit:true
        }))
      else if(order.orderState === 'created')
      setData(prevData=>({
        ...prevData,
        canPay:true
      }))
    }
  },[order])


  if(!order)
    return null

  const handleOnStoreChange = async (e) => {
    let value = e.target.value
    setSelectedLocation(value)
    setData(prevData => ({
      ...prevData,
      pickupStore: value,
      pickupDate: null,
      pickupTime: null
    }))
  }

  const handleOnDateChange = async (e) => {
    let value = e.target.value
    getStoreTimeValues(value)
    setData(prevData => ({
      ...prevData,
      pickupDate: value,
      pickupTime: null
    }))
  }

  const handleOnTimeChange = async (e) => {
    setData(prevData => ({
      ...prevData,
      pickupTime: e.target.value
    }))
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSubmit = async () => {
    let result = await patchOrder({orderId: order.orderId, pickupDate: data.pickupDate, pickupStore: data.pickupStore, pickupTime: data.pickupTime, notes: data.notes})
    if(result.success === true)
      setSend(true)
    else if (result.success === false) {
      setSend(false)
      setErrorMessage(result.message)
    }   
  }

  const handleOnCreatePayment = async () => {
    let result = await fetchReauthorizeOrder(order.orderId)
    if(result.success === true) {
      var link = result.data.filter(x => x.rel === 'approve')
      if(link)
        window.location.href = link[0].href
    }
    else if (result.success === false) {
      setSend(false)
      setErrorMessage(result.message)
    } 
  }

  const getStoreDateValues = () => {
    let newArray = []
    try { newArray = selectedLocationData.opening_hours.periods }
    catch {}
    var items = []
    var defaultAdd = 2
    if(order)
      defaultAdd = order.hasOwnProperty("preparationDays") ? order.preparationDays : 2
    const currentMoment = moment().add(defaultAdd, 'days');
    const endMoment = moment().add(15, 'days');
    while (currentMoment.isBefore(endMoment, 'day')) {
      if(customArrayFilter(newArray, 'day', currentMoment.day()))
        items.push({id:currentMoment.format('YYYY-MM-DD'), name: currentMoment.format('DD.MM.YYYY')})
      currentMoment.add(1, 'days');
    }
    setDateArray(items)
  }

  const getStoreTimeValues = (value) => {
    let newArray = []
    var items = []
    try { 
      newArray = selectedLocationData.opening_hours.periods
    }
    catch {

    }
    const currentMoment = moment(value, 'YYYY-MM-DD')
    let timeData = customArrayFilter(newArray, 'day', currentMoment.day())
    let start = moment(timeData['open'].time, 'HHmm')
    let end = moment(timeData['close'].time, 'HHmm')
    while ( start < end ) {
      items.push({id: start.format('HH:mm'), name: start.format('HH:mm')})
      start = start.add(30, 'minutes')
    }
    setTimeArray(items)
  }

  return(
    <section key={`#order-data`} id={`#order-data`}>
      <Container>
        <Grid divided stackable columns={2}>
          <Grid.Column>
            <Header className={'headline-regular'} as='h1'>{t(`haedline-order_items`)}</Header>
            <Item.Group divided>
              {order && order.items.map((e,index)=>(
                <OrderItem
                  key={`order-item-#${index}`}
                  productId={e.productId}
                  name={e.name}
                  description={e.description}
                  price={e.price}
                  currency={e.currency}
                  qty={e.qty}
                  total={e.total}
                />
              ))}
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span>Zwischensumme</span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span>{order.priceTotal} €</span> 
                </div>
              </div>
              <Divider/>
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span>zzgl. Steuern</span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span>{order.taxTotal} €</span> 
                </div>
              </div>
              <Divider/>
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span><strong>Gesamtsumme</strong></span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span><strong>{order.total} €</strong></span> 
                </div>
              </div>
            </Item.Group>
          </Grid.Column>
          <Grid.Column>
            <Header className={'headline-regular'} as='h1'>{t(`haedline-order`)}</Header>
            <div style={{marginBottom: 30}}>
              <p style={{marginBottom: 0, color: "var(--body-font-color)", fontSize: "1.6rem",fontWeight: 400, fontStyle: "normal"}} >{t(`haedline-order_state`)}</p>
              <span style={{fontSize: 'large'}}>{t(`order-state-${order.orderState}`)}</span>
              {data.canPay && <><br/><br/><span style={{fontSize: 'medium'}}>{t(`content-state-canPay`)}</span></>}
            </div>
            <Form unstackable>
              <p style={{color: "var(--body-font-color)", fontSize: "1.6rem",fontWeight: 400, fontStyle: "normal"}} >{t(`haedline-pickupStore`)}</p>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnStoreChange}
                  value={data.pickupStore ? data.pickupStore : order.pickupStore.id}
                  valueError={data.pickupStoreError}
                  id="store"
                  type="select"
                  label={t('input-label-store')}
                  options={locations}
                  required
                  disabled={!data.canEdit}
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnDateChange}
                  value={data.pickupDate ? data.pickupDate : order.pickupDate}
                  valueError={data.pickupDateError}
                  id="pickup_date"
                  type="select"
                  options={dateArray}
                  label={t('input-label-pickup_date')}
                  required
                  disabled={!data.canEdit}
                />
                <InputField 
                  handleOnChange={handleOnTimeChange}
                  value={data.pickupTime ? data.pickupTime : order.pickupTime}
                  valueError={data.pickupTimeError}
                  id="pickup_time"
                  type="select"
                  options={timeArray}
                  label={t('input-label-pickup_time')}
                  required
                  disabled={!data.canEdit}
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.notes ? data.notes : order.notes}
                  id="notes"
                  type="textarea"
                  label={t('input-label-options')}
                  rows={8}
                  disabled={!data.canEdit}
                />
              </Form.Group>
              <Form.Group>
                {data.canEdit && <DrawButton
                  name={t(`button-change`)}
                  onClick={handleOnSubmit}
                />}
                {data.canPay && <DrawButton
                  name={t(`button-pay`)}
                  onClick={handleOnCreatePayment}
                />}
              </Form.Group>
            </Form>
            {send === true && 
            <Message positive>
              <Message.Header>{t(`headline-order_update-success`)}</Message.Header>
              <p>{t(`content-order_update-success`)}</p>
            </Message>}
            {send === false && 
            <Message negative>
              <Message.Header>{t(`headline-order_update-false`)}</Message.Header>
              <p>{t(`content-order_update-false`)}</p>
              <p>{`${errorMessage}`}</p>
            </Message>} 
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
}

export default Order;