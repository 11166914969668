import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { toggleSidebar } from "../../redux/actions/";
import { useDispatch, useSelector } from 'react-redux';

const CartIcon = (props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cartItems);

  if(props.small)
    return(
      <Menu.Item
        style={{fontFamily: 'Avenir Next Codensed Regular'}}
        className={'header-text-left'}
        key={'checkout'}
        as={Link}
        onClick={()=>dispatch(toggleSidebar(false))}
        to={`/checkout#checkout-data`}
      >
        {t(`header-menu-cart`)}
        {cartItems > 0 && <Label circular color='lg-brown'>
          {cartItems}
        </Label>}
      </Menu.Item>
    );

  return(
    <Menu.Item
      key={'checkout'}
      as={Link}
      to={`/checkout#checkout-data`}
    >
      <Icon.Group>
        <Icon name='shopping cart' inverted color={'grey'} />
        {cartItems > 0 && <Label circular color='lg-brown' floating>
          {cartItems}
        </Label>}
      </Icon.Group>
    </Menu.Item>
  );
}

export default CartIcon;