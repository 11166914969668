import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Input, Loader, Transition, Container, Item, Checkbox, Icon } from 'semantic-ui-react'
import StorServices from './storeservices'

const LocationSearch = (props) => {
  const {t} = useTranslation()

  return(
    <Container>
      <Grid columns={1} style={{margin: '25px'}}>
        <Grid.Column>
          <Input 
            fluid 
            icon={<Icon name='search' onClick={props.onInputChange} link />}
            placeholder={t('input-placeholder-search')} 
            onChange={props.onInputChange}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
            <Checkbox style={{margin: '10px', fontSize: 'large'}} toggle label={t(`service-content-sunday`)} onChange={()=>props.onAttributeClick('sunday')} />
            <Checkbox style={{margin: '10px', fontSize: 'large'}} toggle label={t(`service-content-breakfast`)} onChange={()=>props.onAttributeClick('breakfast')} />
            <Checkbox style={{margin: '10px', fontSize: 'large'}} toggle label={t(`service-content-cafe`)} onChange={()=>props.onAttributeClick('cafe')} />
        </Grid.Column>
      </Grid>
      {!props.stores && <div style={{height: '50vh'}}><Loader active inline='centered' size='large'>{t(`content-loading`)}</Loader></div>}
      <Grid.Row style={{margin: "25px"}}>
        <Transition.Group
          as={Item.Group}
          duration={400}
          divided
          verticalAlign='middle'
        >
          {props.stores && props.stores.map((item) => (
            storeItem(props, item, t)
          ))}
        </Transition.Group>
      </Grid.Row>
    </Container>

  );
}

export default LocationSearch;

function storeItem(props, item, t) {
  return <Item key={`location-store-item-#${item.id}`}>
    <Item.Content>
      <Item.Header as='a' onClick={() => props.onSelectLocation(item.id)} style={{ fontFamily: 'AVENIR NEXT CODENSED ULTRALIGHT' }}>{item.name}</Item.Header>
      <Item.Meta>
        <span className='cinema' style={{ fontFamily: 'Avenir Next Codensed Regular' }}>{`${item.street_address}, ${item.postal_code} ${item.location}`}</span>
      </Item.Meta>
      <Item.Extra>
        <div style={{ display: 'flex' }}>
          {item.attributes.sort(function (a, b) {
              if(a === b) return 0;
              if (a < b)
                  return -1;
              if (a > b)
                  return 1;
              return 0;
            }).map((el) => (
            <StorServices
              type={el}
              name={t(`service-content-${el}`)}
              key={t(`locations-stores-item#${el}`)} />
          ))}
        </div>
      </Item.Extra>
    </Item.Content>
  </Item>
}
