import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/actions/";

import './hamburgerToggle.scss'

const HamburgerToggle = () => {
  const dispatch = useDispatch()
  const sidebar = useSelector((state) => state.sidebar)

  return(
    <div id="hamburger" style={sidebar ? {position: 'fixed'} : {position: 'absolute'}}>
      <button className={sidebar ? 'c-hamburger c-hamburger--htx is-active' : 'c-hamburger c-hamburger--htx'} aria-live="polite" onClick={()=>dispatch(toggleSidebar())} >
        <span id="buttonname" >Navigation zeigen</span>
      </button>
    </div>
  )
}

export default (HamburgerToggle);