import React, { useState } from 'react'

const InputField = (props) => {
  
  const style = {
    group : {
      position: "relative",
      marginBottom: "45px",
      marginRight: "25px",
      width: "100%",
      padding: "0px 10px 0px 10px",
      transition: "all .8s ease"
    },
    highlight : {
      position: "absolute",
      height: "60%",
      width: "100px",
      top: "25%",
      left: 0,
      pointerEvents: "none",
      opacity: .5,
    },
    bar : {
      position: "relative",
      display: "block",
      width: "100%",

      "&:before": {
        left: "50%",
        content: '',
        height: "2px",
        width: 0,
        bottom: "1px",
        position: "absolute",
        background: "var(--secondary-font-color)",
        transition: "0.2s ease all"
      },
      "&:after": {
        right: "50%",
        content: '',
        height: "2px",
        width: 0,
        bottom: "1px",
        position: "absolute",
        background: "var(--secondary-font-color)",
        transition: "0.2s ease all"
      }
    },
    label : {
      color: "var(--body-font-color)",
      fontSize: "1.6rem",
      fontWeight: 400,
      fontStyle: "normal",
      position: "absolute",
      pointerEvents: "none",
      left: "5px",
      top: "1px",
      transition: "0.2s ease all",
    },
    labelFocus : {
      fontWeight: 400,
      fontStyle: "normal",
      position: "absolute",
      pointerEvents: "none",
      left: "5px",
      transition: "0.2s ease all",
      top: "-20px",
      fontSize: "14px",
      color: "var(--secondary-font-color)"
    },
    labelError : {
      color: "var(--main-font-color-error)",
      fontSize: "10px",
      fontWeight: 700,
      position: "absolute",
      left: "5px",
      transition: "all .2s ease",
    },
    notEmpty : {
      fontWeight: 400,
      fontStyle: "normal",
      position: "absolute",
      pointerEvents: "none",
      left: "5px",
      transition: "0.2s ease all",
      top: "-20px",
      fontSize: "14px",
      color: "var(--secondary-font-color)"
    },
    input : {
      fontFamily: "var(--body-font) !important",
      color: "var(--body-font-color) !important",
      lineHeight: "1.15 !important",
      fontSize: "1.8rem !important",
      margin: "0 !important",
      background: "transparent",
      padding: "10px 10px 10px 5px",
      display: "block",
      width: "100%",
      border: "none",
      borderBottom: "1px solid var(--body-font-color)",
    },
    inputFocus : {
      fontFamily: "var(--body-font) !important",
      color: "var(--body-font-color) !important",
      lineHeight: "1.15 !important",
      fontSize: "1.8rem !important",
      margin: "0 !important",
      background: "transparent",
      padding: "10px 10px 10px 5px",
      display: "block",
      width: "100%",
      border: "none",
      outline: "none",
      borderBottom: "2px solid var(--secondary-font-color)"
    },
    labelContent : {
      color: "var(--body-font-color)",
      fontSize: "1.6rem",
      marginLeft: "5px",
      transition: "0.2s ease all"
    },
    checkbox : {
      margin: "10px !important",
      height: "1.6rem",
      msTransform: "scale(1.5)",
      MozTransform: "scale(1.5)",
      WebkitTransform: "scale(1.5)",
      OTransform: "scale(1.5)",
      padding: "10px"
    },
    flex : {
      display: 'flex'
    }
  }

  const [ labelFocus, setLabelFocus ] = useState(false)
  if(props.type === "checkbox")
    return(
      <div style={style.group, style.flex} key={`input-field-#${props.id}`}>
        <input disabled={props.disabled ? props.disabled : false} id={props.id} type="checkbox" required={props.required} defaultChecked={props.checked && props.checked} onClick={props.handleOnChange} style={style.checkbox}/>
        <span style={style.labelContent} >{props.label}</span>
      </div>
    )
  else if(props.type === "textarea")
    return(
      <div style={style.group} key={`input-field-#${props.id}`}>      
        <textarea disabled={props.disabled ? props.disabled : false} defaultValue={props.value && props.value} id={props.id} rows={props.rows || 3} type={props.type} style={labelFocus ? style.inputFocus : style.input} required={props.required} onFocus={()=>setLabelFocus(true)} onBlur={()=>setLabelFocus(false)} onChange={props.handleOnChange} />
        <span style={style.highlight}></span>
        <span style={style.bar}></span>
        <label style={props.value ? style.notEmpty : labelFocus ? style.labelFocus : style.label}>{props.label}{props.required && '*'}</label>
        <span style={style.labelError} >{props.valueError}</span>
      </div>
    )
  else if(props.type === "select")
    return(
      <div style={style.group} key={`input-field-#${props.id}`}>      
        <select disabled={props.disabled ? props.disabled : false} id={props.id} type={props.type} style={labelFocus ? style.inputFocus : style.input} required={props.required} onFocus={()=>setLabelFocus(true)} onBlur={()=>setLabelFocus(false)} onChange={props.handleOnChange} >
        {!props.value && <option value=''></option>}
        {props.options && props.options.map((e)=>(
          <option key={`input-field-#${props.id}-option-#${e.id}`} selected={e.id === props.value} value={e.id}>{e.name}</option>
        ))}
        </select>
        <span style={style.highlight}></span>
        <span style={style.bar}></span>
        <label style={props.value ? style.notEmpty : labelFocus ? style.labelFocus : style.label}>{props.label}{props.required && '*'}</label>
        <span style={style.labelError} >{props.valueError}</span>
      </div>
  )
  else
    return(
      <div style={style.group} key={`input-field-#${props.id}`}>      
        <input disabled={props.disabled ? props.disabled : false} defaultValue={props.value && props.value} id={props.id} type={props.type} style={labelFocus ? style.inputFocus : style.input} required={props.required} onFocus={()=>setLabelFocus(true)} onBlur={()=>setLabelFocus(false)} onChange={props.handleOnChange}  onKeyDown={props.onKeyDown && props.onKeyDown} />
        <span style={style.highlight}></span>
        <span style={style.bar}></span>
        <label style={props.value ? style.notEmpty : labelFocus ? style.labelFocus : style.label}>{props.label}{props.required && '*'}</label>
        <span style={style.labelError} >{props.valueError}</span>
      </div>
    )
}

export default (InputField);