import config from '../../config/main.json'

export function storeStructuredData({
  id,
  name,
  postalCode,
  streetAddress,
  location,
  latitude,
  longitude,
  phone,
  }) {

  if(!phone)
    phone = config.phone

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "name" : name,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": streetAddress,
      "addressLocality": location,
      "addressRegion": "BY",
      "postalCode": postalCode,
      "addressCountry": "DE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": latitude,
      "longitude": longitude
    },
    "url": `https://bhmm.de/mystore/${id}#places`,
    "telephone": phone,
    "menu": "https://bhmm.de/products#home",
    "logo": "https://bhmm.de/ios/256.png"
  });
}