import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Menu } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import './footer.scss'

const Navigation = (props) => {
  const {t} = useTranslation()

  const renderItems = (menuItems) => {
    var items = []
    if(menuItems){
      menuItems.sort((a,b) => a.position - b.position);
      menuItems.forEach(element => {
        let newTab = false
        if(element.link.indexOf('https://') > -1 )
          newTab = true

        items.push(
          <Menu.Item
            className={'inverted'}
            key={`menu-item-${element.name}`}
            name={t(`menu-item-${element.name}`)}
            active={props.activeItem === t(`menu-item-${element.name}`)}
            as={Link}
            to={newTab ? element.link : `/${element.link}`}
            target={newTab ? '_blank' : ''}
            style={{fontSize: '1.4rem'}}
          >
            {t(`menu-item-${element.name}`)}
          </Menu.Item>
        )
      });
    }
    return items
  }

  return(
    <Grid.Column textAlign={props.size < 770 && 'center'} >
      <Menu text vertical inverted style={{margin: 'auto'}}>
        <Menu.Item header style={{fontSize: '1.4rem'}} className={'footer-navigation'} >{t(`menu-item-${props.section}`)}</Menu.Item>
        {renderItems(props.menuItems)}
      </Menu>
    </Grid.Column>
  );
}

export default Navigation;