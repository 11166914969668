import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Container, Header } from 'semantic-ui-react'
import { PhoneButton, GoogleMapsButton } from '../button'

import card from '../../images/mehrLiebe.png'
import background from '../../images/3618C85D-7617-490E-B9CA-1B3227DAA7A1.webp'
import data from '../../config/main.json'

const ContactHeader = (props) => {
  const {t} = useTranslation()

  return(
    <section key={props.id} id={`#${props.id}`}>
      <Container>
        <article className='article horizontal img-first'>
            <div className='article__txt' >
              <Header className={'headline-regular'} as='h1'>{t('haedline-contact')}</Header>
              <Header className={'headline-bag'} as='h3'>{t('sub-haedline-contact')}</Header>
              <p>
                {t('content-contact')}
              </p>
              <Header className={'headline-bag'} as='h3'>{t(`headline-contact`)}</Header>
              <GoogleMapsButton
                postalCode={data.postalCode}
                location={data.locality}
                streetAddress={data.streetAddress}
                mapsLink={props.mapsLink}
              />
              <PhoneButton
                phone={data.phone}
              />
            </div>
            <div className='article__img' >
              <div className="img" style={{backgroundImage: `url(${background})`}}></div>
              <div className="img-stamp">
                <Image alt={t(`headline-slider-home`)} size='tiny' src={card} />
              </div>
            </div>
        </article>
      </Container>
    </section>
  );
}

export default ContactHeader;