export const customArrayFilter = (object, key, value) => {
  if (Array.isArray(object)) {
    for (const obj of object) {
      const result = customArrayFilter(obj, key, value);
      if (result) {
        return obj;
      }
    }
  } else {
    if (object.hasOwnProperty(key) && object[key] === value) {
      return object;
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === "object") {
        const o = customArrayFilter(object[k], key, value);
        if (o !== null && typeof o !== 'undefined')
          return o;
      }
    }

    return null;
  }
}