import { LOADING_START, TOGGLE_SIDEBAR, LOADING_STOP, TOGGLE_MENUCARD, NOTIFICATION_ADD, NOTIFICATION_REMOVE } from "../constants/action-types";
import { v4 as uuidv4 } from 'uuid';

export function toggleSidebar(){
  return (dispatch) => {
    dispatch({type:TOGGLE_SIDEBAR})
  }
}

export function toggleMenuCard(){
  return (dispatch) => {
    dispatch({type:TOGGLE_MENUCARD})
  }
}

export function startLoading() {
  return (dispatch) => {
    dispatch({type: LOADING_START})
  }
}

export function stopLoading() {
  return (dispatch) => {
    dispatch({type: LOADING_STOP})
  }
}

export function addNotification(header, content, icon) {
  return (dispatch) => {
    dispatch({type:NOTIFICATION_ADD, result: {id:uuidv4(),icon: icon, header: header, content: content}})
  }
}

export function removeNotification(id) {
  return (dispatch) => {
    dispatch({type:NOTIFICATION_REMOVE, result: id})
  }
}