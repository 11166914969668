import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Segment, Grid, Header, Input } from 'semantic-ui-react'
import { DrawButton } from '../button'

const CareerSearch = (props) => {
  const {t} = useTranslation()
  const style = {
    input: {
      maxWidth: "100%",
      marginLeft: 0,
      marginRight: 0,
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.onSubmit()
    }
  }

  return(
    <section key={props.id} id={`#${props.id}`}>
      <Container fluid>
        <Segment placeholder>
          <Header className={'headline-regular'} as='h1' textAlign='left'>{t(`headline-career-search`)}</Header>
          <Grid padded stackable columns={2} verticalAlign='middle'>
            <Grid.Column>
              <Input style={style.input} fluid size='big' placeholder={t(`placeholder-search`)} onKeyDown={handleKeyDown} onChange={props.onInputChange}/>
            </Grid.Column>
            <Grid.Column>
              <DrawButton
                name={t(`button-search`)}
                onClick={props.onSubmit}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    </section>
  );
}

export default CareerSearch;