import React, { useEffect, useState } from "react";

import './styles.scss'

const Panel = (props) => {
  const { label, content, activeTab, index, activateTab } = props
  const [ isActive, setActive ] = useState(false)

  useEffect(() => {
    if(index === activeTab)
      setActive(true)
    else
      setActive(false)
  },[activeTab])

  const innerStyle = {
    height:  `${isActive ? 'auto' : 0}`
  }
  
  return (
    <div className='panel'
      role='tabpanel'
      aria-expanded={isActive}>
      <button className='panel__label headline-regular'
        role='tab'
        onClick={()=>activateTab(index)}>
        { label }
      </button>
      <div className='panel__inner'
        style={innerStyle}
        aria-hidden={!isActive}>
        <p className='panel__content'>
          {content}
        </p>
      </div>
    </div>
  );
}

const Accordion = (props) => {
  const [ activeTab, setActiveTab ] = useState(0)
	const { panels } = props

  return (
    <div className='accordion' role='tablist'>
      {panels.map((panel, index) =>
        <Panel
          key={index}
          activeTab={activeTab}
          index={index}
          { ...panel } 
          activateTab={setActiveTab}
        />
      )}
    </div>
  );
}

export default Accordion