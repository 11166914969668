import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { Image, Transition } from 'semantic-ui-react';
import LoadingImage from '../../images/logo.svg'
import "./loader.scss"

const Loader = props => {
  const loading = useSelector((state) => state.loading)
  const [visible, setVisible] = useState(false)
  const hide = 1000
  const show = 1000

  useEffect(() => {
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
    },show);

  },[loading])

  if(!loading || loading === false)
    return null

  return(
    <div className="loaderCover">
      <div className="laoderSpinner">
        <Transition duration={{ hide, show }} visible={visible}>
          <Image centered size='medium' src={LoadingImage} alt='Backhaus Häussler Loading Animation'/>
        </Transition>
      </div>
    </div>
  )
}

export default (Loader);