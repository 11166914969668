import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Loader, Container } from 'semantic-ui-react'
import { CareerSearch, CareerList, CareerFooter } from '../components/career'
import { fetchJobs } from '../utils/requestHelper'
import {Helmet} from "react-helmet"
import { ArticleHorizontalVideo } from '../components/section'
import config from '../config/main.json'
import background from '../images/haeussler-jobs-background.webp'
import stamp from '../images/mehrLaecheln.png'
import mp4 from '../images/video/video-output-career.mp4'
import { JobOffers, IconGrid } from '../components'

const jobArray = [
  {
    headline: 'Verkauf',
    image: '45C878CC-017F-403D-BAFF-9FCA6EE116F0.webp',
    job: 'Verkauf/ Filiale',
    link: 'Verkauf'
  },
  {
    headline: 'Bäcker',
    image: 'IMG_5999.webp',
    job: 'Bäckerei/ Produktion',
    link: 'Backstube'
  },
  {
    headline: 'Verwaltung',
    image: 'IMG_5997.webp',
    job: 'Verwaltung/ Büro',
    link: 'Verwaltung'
  },
  {
    headline: 'Versand',
    image: 'IMG_6001.webp',
    job: 'Logistik/ Versand',
    link: 'Logistik'
  },
  {
    headline: 'Technik',
    image: 'IMG_6100.webp',
    job: 'Haustechnik',
    link: 'Technik'
  },
  {
    headline: 'Ausbildung',
    image: '57E10DB5-C7FF-4BE9-85E6-A1CC9CC97745.webp',
    job: 'Ausbildung',
    link: 'Ausbildung'
  }
]

const Career = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const resultRef = useRef()
  const jobsPerPage = 6
  const [ filter, setFilter ] = useState()
  const [ jobResults, setJobResults ] = useState()
  const [ loading, setLoading ] = useState(false)
  const [ pignation, setPignation ] = useState({defaultActivePage: 1, totalPages: 1, activePage: 1 });

  const addedValues = [
    {
      content: t('job-addedValues-01'),
      image: 'trophy-svg.svg',
    },
    {
      content: t('job-addedValues-02'),
      image: 'chart-svg.svg',
    },
    {
      content: t('job-addedValues-03'),
      image: 'globe-svg.svg',
    },
    {
      content: t('job-addedValues-04'),
      image: 'calendar-svg.svg',
    },
    {
      content: t('job-addedValues-05'),
      image: 'money-svg.svg',
    },
    {
      content: t('job-addedValues-06'),
      image: 'cake-svg.svg',
    },
    {
      content: t('job-addedValues-07'),
      image: 'bike-svg.svg',
    },
    {
      content: t('job-addedValues-08'),
      image: 'heart-svg.svg',
    },
  ]

  useEffect(() => {
    const { search, state } = location
    async function fetchJobData(data) {
      setLoading(true)
      let response = await fetchJobs(data, jobsPerPage, 0)
      if(response.success === true){
        setJobResults(response)
        resultRef.current.scrollIntoView(true)
        if(response.count === 0)
        setJobResults({
          ...response,
          data: [{jobId: 'f6541f74f58c4897955b01dfb8b2f36c', name: 'Mitarbeiter im Backhaus Häussler', location: { location: 'Alle Standorte' }, department: 'Initiativbewerbung', subscription: 'Vollzeit, Teilzeit, ...'}]
        })
        if(response.count > jobsPerPage)
          setPignation(prevData => ({
            ...prevData,
            totalPages: Math.ceil(response.count / jobsPerPage)
          }))
      }
      setLoading(false)
    }
    if(search === '?state=search') {
      fetchJobData()
    }
  },[location]);

  const handleOnSelectType = async (filter) => {
    setLoading(true)
    setFilter(filter)
    let response = await fetchJobs(filter, jobsPerPage, 0)
    if(response.success === true){
      setJobResults(response)
      resultRef.current.scrollIntoView(true)
      if(response.count === 0)
        setJobResults({
          ...response,
          data: [{jobId: 'f6541f74f58c4897955b01dfb8b2f36c', name: 'Mitarbeiter im Backhaus Häussler', location: { location: 'Alle Standorte' }, department: 'Initiativbewerbung', subscription: 'Vollzeit, Teilzeit, ...'}]
        })
      if(response.count > jobsPerPage)
        setPignation(prevData => ({
          ...prevData,
          activePage: 1 ,
          totalPages: Math.ceil(response.count / jobsPerPage)
        }))
      else
        setPignation({defaultActivePage: 1, totalPages: 1, activePage: 1 })
    }
    setLoading(false)
  }

  const handleOnSetPagination = async (e, { activePage }) => {
    let multi = jobsPerPage * (activePage -1)
    if(activePage === 1)
      multi = 0

    let response = await fetchJobs(filter, jobsPerPage, multi)
    if(response.success === true){
      setJobResults(response)
      resultRef.current.scrollIntoView(true)
      if(response.count > jobsPerPage)
        setPignation(prevData => ({
          ...prevData,
          activePage: activePage,
          totalPages: Math.ceil(response.count / jobsPerPage)
        }))
    }
  };

  const handleOnChangeFilter = (e) => {
    const { value } = e.target
    setFilter(value)
  }

  const handleOnSendFilter = () => {
    async function fetchJobData() {
      setLoading(true)
      let response = await fetchJobs(filter, jobsPerPage, 0)
      if(response.success === true){
        setJobResults(response)
        resultRef.current.scrollIntoView(true)
        if(response.count === 0)
        setJobResults({
          ...response,
          data: [{jobId: 'f6541f74f58c4897955b01dfb8b2f36c', name: 'Mitarbeiter im Backhaus Häussler', location: { location: 'Alle Standorte' }, department: 'Initiativbewerbung', subscription: 'Vollzeit, Teilzeit, ...'}]
        })
        if(response.count > jobsPerPage)
          setPignation(prevData => ({
            ...prevData,
            activePage: 1 ,
            totalPages: Math.ceil(response.count / jobsPerPage)
          }))
        else
          setPignation({defaultActivePage: 1, totalPages: 1, activePage: 1 })
      }
      setLoading(false)
    }
    fetchJobData()
  }

  const handleOnSelectJob = (e) => {
    navigate(`/jobs/${e}`)
  }

  return (
    <main>
      <Helmet>
        <meta name="google-site-verification" content={config.googleSiteVerification} />
      </Helmet>
      <ArticleHorizontalVideo
        id={'career'}
        headline={t(`haedline-career`)}
        subline={t(`sub-headline-career`)}
        content={[t(`content-career-01`),t(`content-career-02`)]}
        image={background}
        mp4={mp4}
        card={stamp}
      />
      <IconGrid 
        icons={addedValues} 
      />
      <Container>
        <CareerSearch
          onInputChange={handleOnChangeFilter}
          onSubmit={handleOnSendFilter}
        />
        <div ref={resultRef} style={{margin: '50px'}}><Loader active={loading} inline='centered'  size='large'>{t(`content-loading`)}</Loader></div>
        {(!loading && jobResults) && <CareerList
          jobData={jobResults}
          pignation={pignation}
          setPignation={handleOnSetPagination}
          onJobSelect={handleOnSelectJob}
        />}
        <JobOffers 
          id={'job-offers'}
          offers={jobArray}
          onItemClick={handleOnSelectType}
        />
      </Container>
      <CareerFooter />
    </main>
  );
}

export default Career;