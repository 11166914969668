import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Form, Transition, Grid } from 'semantic-ui-react';
import { InputField } from '..';
import { DrawButton } from '../button';

const ApplicationAddress = (props) => {
  const {t} = useTranslation()
  return(
    <Transition visible={props.show} animation='scale' duration={500}>
      <section style={{margin:10}}>
        <Header className={'headline-regular'} as='h1'>{t('headline-application_address')}</Header>
        <p>
          {t('content-application_address')}
        </p>
        <Form style={{padding: 15}}>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.postalCode}
              valueError={props.postalCodeError}
              id="postalCode"
              type="text"
              label={t('input-label-postalCode')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.location}
              valueError={props.locationError}
              id="location"
              type="text"
              label={t('input-label-location')}
              required
            />
          </Form.Group>
          <Form.Group>
            <InputField 
              handleOnChange={props.handleOnChange}
              value={props.streetAddress}
              valueError={props.streetAddressError}
              id="streetAddress"
              type="text"
              label={t('input-label-streetAddress')}
              required
            />
          </Form.Group>
        </Form>
        <Grid columns='equal'>
          <Grid.Column>
            <DrawButton
              name={t(`button-back`)}
              onClick={props.handleOnBack}
            />
          </Grid.Column>
          <Grid.Column>
            <DrawButton
              name={t(`button-next`)}
              onClick={props.handleOnSubmit}
            />
          </Grid.Column>
        </Grid>
      </section>
    </Transition>
  )
}

export default ApplicationAddress