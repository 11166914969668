import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Loader } from 'semantic-ui-react'
import { CareerOffer, CareerFooter } from '../components/career'
import {Helmet} from "react-helmet"
import config from '../config/main.json'

import { fetchJob, postJobStatistic } from '../utils/requestHelper'

const Job = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const params = useParams();
  const search = useLocation().search;

  const [ selectedJob, setSelectedJob ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    const { jobId } = params
    async function fetchJobData(id) {
      setLoading(true)
      let response = await fetchJob(id)
      if(response.success === true) {
        setSelectedJob(response.data)
        const utmSource = new URLSearchParams(search).get('utm_source') || 'bhmm_jobs_apply'
        if(utmSource !== 'preview')
          await postJobStatistic(id, utmSource)
      }
      else
        navigate(`/jobs`)
      setLoading(false)
    }
    if(jobId)
      fetchJobData(jobId)
    else
      navigate(`/jobs`)
  },[params.jobId, search]);

  const handleOnClickApplication = (e) => {
    window.open(`/application/${e}`, '_blank')
  }

  return (
    <main>
      <Helmet>
        <meta name="google-site-verification" content={config.googleSiteVerification} />
      </Helmet>
      <div style={{margin: '50px'}}><Loader active={loading} inline='centered'  size='large'>{t(`content-loading`)}</Loader></div>
      {selectedJob && <CareerOffer
        name={selectedJob.name}
        identifier={selectedJob.identifier}
        description={selectedJob.description}
        department={selectedJob.department}
        organizer={selectedJob.organizer}
        entryLevel={selectedJob.entryLevel}
        subscription={selectedJob.subscription}
        activities={selectedJob.activities}
        skills={selectedJob.skills}
        location={selectedJob.location}
        createdAt={selectedJob.createdAt}
        modifiedAt={selectedJob.modifiedAt}
        isActive={selectedJob.isActive}
        jobId={selectedJob.jobId}
        onSelect={handleOnClickApplication}
      />}
      <CareerFooter />
    </main>
  );
}

export default Job;