import {NETWORK_ERROR, NETWORK_START, NETWORK_STOP, LOADING_START, LOADING_STOP, TOGGLE_SIDEBAR, TOGGLE_MENUCARD, STORES_LOAD, STORES_SETME, CATEGORIES_SET, PRODUCTS_SET, CART_REMOVE, CART_ADD, CART_LOAD, CART_CLEAR, NOTIFICATION_ADD, NOTIFICATION_REMOVE } from '../constants/action-types'

import headerMenu from  '../../config/headerMenu.json'
import footerMenu from  '../../config/footerMenu.json'

const initialState = {
  loading: false,
  sidebar: false,
  networkRequests: 0,
  lastError: null,
  headerMenu: headerMenu,
  footerMenu: footerMenu,
  myStore: null,
  stores: null,
  menuCard: false,
  productsCategories: null,
  products: null,
  cartItems: 0,
  notification: null
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case NETWORK_ERROR:
      return { ...state, lastError: action.error };
    case NETWORK_START:
      return { ...state, networkRequests: state.networkRequests + 1 };
    case NETWORK_STOP:
      return { ...state, networkRequests: state.networkRequests - 1 };
    case LOADING_START:
      return { ...state, loading: true };
    case LOADING_STOP:
      return { ...state, loading: false };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case TOGGLE_MENUCARD:
      return { ...state, menuCard: !state.menuCard };
    case STORES_LOAD:
      return { ...state, stores: action.result};
    case STORES_SETME:
        return { ...state, myStore: action.result};
    case CATEGORIES_SET:
      return { ...state, productsCategories: action.result};
    case PRODUCTS_SET:
      return { ...state, products: action.result};
    case CART_LOAD:
      return { ...state, cartItems: action.result };
    case CART_ADD:
      return { ...state, cartItems: state.cartItems + 1 };
    case CART_REMOVE:
      return { ...state, cartItems: state.cartItems - 1 };
    case CART_CLEAR:
      return { ...state, cartItems: 0 };
    case NOTIFICATION_ADD:
      return { ...state, notification: state.notification ? [...state.notification, action.result] : [action.result]};
    case NOTIFICATION_REMOVE:
      return { ...state, notification: state.notification ? state.notification.filter(x => x.id !== action.result) : null};
    default:
      return state;
  }
}

export default rootReducer;