import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, PartnerCards } from '../components'
import { Article } from '../components/section'

import background from '../images/image-associate-haeussler.webp'

const Associate = (props) => {
  const {t} = useTranslation()

  return (
    <main>
      <Article
        id={'partner'}
        headline={t(`haedline-partner`)}
        subline={t(`sub-headline-partner`)}
        content={t(`content-partner`)}
        image={background}
      />
      <PartnerCards />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Associate;