import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Image, Header } from 'semantic-ui-react'
import webdesign from '../images/webdesign.png'
import config from '../config/main.json'

const About = (props) => {
  const {t} = useTranslation()

  return (
    <main>
      <Container className={'section'} key={`container-about`} id={`container-about`}>
        <Header className={'headline-regular'} as='h1'>{t('page-about-headline')}</Header>
        <Header className={'headline-regular'} as='h2'>{t('page-about-headline-information')}</Header>
        <p>{config.company}</p>
        <p>{config.streetAddress}</p>
        <p>{config.postalCode} {config.locality}</p>
        <p><strong>{t('page-about-represented')}</strong></p>
        <p>{config.businessOrganisation}</p>
        <p>{t('page-about-business-manager')}: {config.businessManager}</p>
        <p>
          {config.localCourt}<br/>
          {config.localCourtNumber}
        </p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-contact-headline')}</Header>
        <p>{t('page-about-contact-phone')}: {config.phone}</p>
        <p>{t('page-about-contact-mail')}: {config.mail}</p>
        <h2 className="headline-dark">{t('page-about-tax-headline')}</h2>
        <p>{t('page-about-tax-content')}</p>
        <p>{config.ustId}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-dispute-headline')}</Header>
        <p>{t('page-about-dispute-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-contentLiability-headline')}</Header>
        <p>{t('page-about-contentLiability-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-linksLiability-headline')}</Header>
        <p>{t('page-about-linksLiability-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-copyright-headline')}</Header>
        <p>{t('page-about-copyright-content')}</p>
        <Header className={'headline-regular'} as='h2'>{t('page-about-design-headline')}</Header>
        <p>{t('page-about-design-content')}</p>
        <Image src={webdesign} size='large' alt="Dennis Rupp Webdesign" />
      </Container>
    </main>
  );
}

export default About;