import React from 'react'
import './buttons.scss'

const DrawButton = (props) => {

  const handleOnClick = () => {
    if(!props.disabled)
      props.onClick()
  }

  const style = {
    btn : {
      fontSize: props.fontSize ? props.fontSize : "1.8em",
      textTransform: "uppercase",
      textDecoration: "none",
      letterSpacing: "0.1em"
    }
  }

  return(
    <a 
      className="btn-draw"
      style={style.btn}
      onClick={handleOnClick}
    >
      <span>{`${props.name}`}</span>
    </a>
  );
}

export default DrawButton