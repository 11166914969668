import React from 'react'
import { Locations } from '../components'

const Places = () => {

  return (
    <main>
      <Locations
        id={'places'}
      />
    </main>
  );
}

export default Places;