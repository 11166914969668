import moment from 'moment'
import config from '../../config/main.json'
import translation from '../../locale/de/translate.json'

const createDescription = (description, activities, skills) => {
  let text = ``
  text += `<p>${description}</p>`
  if(activities.length > 0) {
    text += `<p>Deine Aufgaben</p>`
    text += `<ul>`
    activities.map(e => {
      text += `<li>${e.name}</li>`
    })
    text += `</ul>`
  }
  if(skills.length > 0) {
    text += `<p>Deine Kompetenzen</p>`
    text += `<ul>`
    skills.map(e => {
      text += `<li>${e.name}</li>`
    })
    text += `</ul>`
  }
  text += `<p>Arbeiten bei Häussler</p>`
  text += `<p>${translation.translations['content-career-01']}</p>`

  return text
}

export function articleStructuredData({
  title,
  description,
  activities,
  skills,
  location,
  streetAddress,
  postalCode,
  identifier,
  subscription,
  createdAt
  }) {
  const employmentType = (text) => {
    switch (text) {
      case 'Flexibel':
        return ['FULL_TIME', 'PART_TIME', 'TEMPORARY']
      case 'Vollzeit':
        return 'FULL_TIME'
      case 'Teilzeit':
        return 'PART_TIME'
      case 'Mini Job':
        return 'TEMPORARY'
      case 'Praktikum':
        return 'INTERN'
      default:
        return 'CONTRACTOR'
    }
  }
  return JSON.stringify({
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : title,
    "description" : createDescription(description,activities,skills),
    "identifier": {
      "@type": "PropertyValue",
      "name": config.company,
      "value": identifier
    },
    "datePosted" : createdAt,
    "validThrough": moment(new Date, 'YYYY-MM-DD').add(12, 'months'),
    "directApply": true,
    "employmentType" : employmentType(subscription),
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : config.company,
      "sameAs" : "https://bhmm.de",
      "logo" : "https://bhmm.de/ios/256.png"
    },
    "jobLocation": {
    "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": streetAddress,
      "addressLocality": location,
      "postalCode": postalCode,
      "addressCountry": "DE",
      "addressRegion": "BY"
      }
    }
  });
}