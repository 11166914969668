import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram } from '../components'

import { Article, ArticleHorizontal, ImageFull } from '../components/section'

import bg_breze from '../images/image-seelenverkaeufer-haeussler.webp'
import background from '../images/image-catering-haeussler.webp'
import stamp from '../images/mehrLiebe.png'

import image2 from '../images/image_full_mobile_02.webp'

const Mobile = () => {
  const {t} = useTranslation()

  return (
    <main>
      <ArticleHorizontal
        id={'mobile'}
        headline={t(`haedline-mobile`)}
        subline={t(`sub-headline-mobile`)}
        content={[t(`content-mobile-01`),t(`content-mobile-02`)]}
        image={background}
        card={stamp}
      />
      <Article
        id={'seelenverkaufer'}
        headline={t(`haedline-seelenverkaufer`)}
        subline={t(`sub-headline-seelenverkaufer`)}
        content={t(`content-seelenverkaufer`)}
        image={bg_breze}
      />
      <ImageFull 
        id={'fullimage02'} 
        image={image2} 
      />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default Mobile;