import React from 'react'
import { useTranslation } from 'react-i18next'
import { Item, Header, Icon } from 'semantic-ui-react'

import jobImage from './images/jobs.jpg'

const CareerListItem = (props) => {
  const {t} = useTranslation()

  return(
    <Item onClick={()=>props.btn(props.jobId)}>
      <Item.Image circular size='small' src={jobImage} />
      <Item.Content verticalAlign='middle'>
        <Item.Header>{props.title}</Item.Header>
        <Item.Meta>
          <span className='cinema'>{`${props.location.location}, ${props.department}, ${props.subscription}`}</span>
        </Item.Meta>
        <Item.Description>
          <Header as='h4'>
            <Icon.Group >
              <Icon link name='pin' />
            </Icon.Group>
            {t(`button-to_application`)}
          </Header>
        </Item.Description> 
      </Item.Content>
    </Item>
  )
}

export default CareerListItem;