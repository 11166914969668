import React from 'react'
import { useTranslation } from 'react-i18next'
import { Instagram, Training } from '../components'

import { Article, ArticleHorizontal } from '../components/section'

import background from '../images/haeussler-job-01.webp'
import background2 from '../images/haeussler-job-03.webp'
import stamp from '../images/mehrHeimat.png'

const JobEducation = () => {
  const {t} = useTranslation()

  return (
    <main>
      <ArticleHorizontal
        id={'education'}
        headline={t(`haedline-education`)}
        subline={t(`sub-headline-education`)}
        content={t(`content-education`)}
        image={background}
      />
      <Article
        id={'schooling'}
        headline={t(`haedline-schooling`)}
        subline={t(`sub-headline-schooling`)}
        content={t(`content-schooling`)}
        image={background2}
        card={stamp}
      />
      <Training
        bgColor={'#fff'}
      />
      <Instagram
        id={'instagram'}
      />
    </main>
  );
}

export default JobEducation;