import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getWithExpiry, getItem } from '../../utils/storageHelper'
import moment from 'moment'
import { customArrayFilter } from '../../utils/filterHelper'
import { fetchCart, addCartItem, removeCartItem, fetchLocations, fetchLocation, checkoutCart } from '../../utils/requestHelper'
import { Container, Grid, Header, Form, Item, Divider, Message, Image } from 'semantic-ui-react'
import { InputField } from '../'
import { DrawButton } from '../button'
import CartItem from './cartItem'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, clearCart } from '../../redux/actions'
import payPalLogo from '../../images/1280px-PayPal_logo.svg.png'

const Checkout = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartItems = useSelector((state)=>state.cartItems)
  const [ cart, setCart ] = useState()
  const [ locations, setLocations ] = useState()
  const [ selectedLocation, setSelectedLocation ] = useState()
  const [ selectedLocationData, setSelectedLocationData ] = useState()
  const [ send, setSend ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState({
    givenname: null,
    surname: null,
    mail: null,
    phone: null,
    pickupStore: null,
    pickupDate: null,
    pickupTime: null,
    mailError: null,
    phoneError: null,
    givennameError: null,
    surnameError: null,
    pickupStoreError: null,
    pickupDateError: null,
    pickupTimeError: null,
    notes:null
  })

  const [ dateArray, setDateArray ] = useState([])
  const [ timeArray, setTimeArray ] = useState()

  const reloadCart = async (value) => {
    let response = await fetchCart(value)
    if(response)
      setCart(response.data)
  }

  useEffect(() => {
    reloadCart(data.pickupDate)
  },[cartItems])

  useEffect(() => {
    const getLocationData = async(storeId) => {
      var result = await fetchLocation(storeId)
      if(result.success === true){
        setSelectedLocationData(result.data)
      }
    }
    if(selectedLocation){
      getLocationData(selectedLocation)
    }
  },[selectedLocation])

  useEffect(() => {
    if(selectedLocationData){
      getStoreDateValues()
    }
  },[selectedLocationData])

  useEffect(() => {
    const getLocations = async() => {
      let result = await fetchLocations(true)
      if(result.success === true){
        let filteredLocations = result.data.filter(x => x.pickup_store === 1)
        setLocations(filteredLocations)
        let defaultStore = getItem('myStore')
        if(defaultStore){
          setSelectedLocation(defaultStore)
          setData(prevData => ({
            ...prevData,
            pickupStore: defaultStore
          }))
        }
      }
    }
    const fetchData = async () => {
      let response = await fetchCart()
      setCart(response.data)
    }
    let newCartId = getWithExpiry('cartId')
    if(newCartId) {
      fetchData()
    }
    getLocations()
  },[])

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetchCart()
      setCart(response.data)
    }
    let newCartId = getWithExpiry('cartId')
    if(newCartId) {
      fetchData()
    }
  },[])

  const checkCurrentTimeAfterClose = () => {
    var now = moment();
    var hourToCheck = (now.day() !== 0)?18:0;
    var dateToCheck = now.hour(hourToCheck).minute(0);
    return (moment().isAfter(dateToCheck))
  }

  const handleOnItemPlus = async (id) => {
    var result = await addCartItem(id)
    if(result.success === true)
      dispatch(addToCart())
  }

  const handleOnItemMinus = async (id) => {
    var result = await removeCartItem(id)
    if(result.success === true){
      dispatch(removeFromCart())
    }
  }

  const handleOnStoreChange = async (e) => {
    let value = e.target.value
    setSelectedLocation(value)
    setData(prevData => ({
      ...prevData,
      pickupStore: value,
      pickupDate: null,
      pickupTime: null
    }))
  }

  const handleOnDateChange = async (e) => {
    let value = e.target.value
    let response = await fetchCart(value)
    if(response) {
      setCart(response.data)
      getStoreTimeValues(value, response.data)
      setData(prevData => ({
        ...prevData,
        pickupDate: value,
        pickupTime: null
      }))
    }
  }

  const handleOnTimeChange = async (e) => {
    setData(prevData => ({
      ...prevData,
      pickupTime: e.target.value
    }))
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeCheckbox = () => {
    setData(prevData => ({
        ...prevData,
        iAgree: !data.iAgree
    }))
  }

  const handleOnChangeConditions = () => {
    setData(prevData => ({
      ...prevData,
      conditions: !data.conditions
    }))
  }

  const validateForm = () => {
    const errors = {
      mailError: null,
      givennameError: null,
      surnameError: null,
      pickupStoreError: null,
      pickupDateError: null,
      pickupTimeError: null,
      verificationState: true
    }

    if(!data.givenname){
      errors.givennameError = t('error-empty-givenname')
      errors.verificationState = false
    }
    if(!data.surname){
      errors.surnameError = t('error-empty-surname')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }
    if(!data.pickupStore){
      errors.pickupStoreError = t('error-empty-pickupStore')
      errors.verificationState = false
    }
    if(!data.pickupDate){
      errors.pickupDateError = t('error-empty-pickupDate')
      errors.verificationState = false
    }
    if(!data.pickupTime){
      errors.pickupTimeError = t('error-empty-pickupTime')
      errors.verificationState = false
    }
    if(!data.iAgree){
      errors.iAgreeError = true
      errors.verificationState = false
    }
    if(!data.conditions){
      errors.conditionsError = true
      errors.verificationState = false
    }
    if(cart.isAvailable !== true) {
      errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnSubmit = async () => {
    if(validateForm() && cart){
      setLoading(true)
      var result = await checkoutCart(cart.cartId,data)
      if(result.success === true) {
        var link = result.data.filter(x => x.rel === 'approve')
        if(link)
          window.location.href = link[0].href
        setSend(true);
        setTimeout(() => {
          dispatch(clearCart())
          navigate('/')
        },5000);
      }
      setLoading(false)
    }
  }

  const getStoreDateValues = () => {
    let newArray = []
    try { newArray = selectedLocationData.opening_hours.periods }
    catch {}
    var items = []
    var defaultAdd = 1
    if(cart)
      defaultAdd = cart.hasOwnProperty("preparationDays") ? cart.preparationDays : 1
    if(checkCurrentTimeAfterClose())
      defaultAdd = defaultAdd + 1
    const currentMoment = moment().add(defaultAdd, 'days');
    const endMoment = moment().add(15, 'days');
    while (currentMoment.isBefore(endMoment, 'day')) {
      if(customArrayFilter(newArray, 'day', currentMoment.day()))
        items.push({id:currentMoment.format('YYYY-MM-DD'), name: currentMoment.format('DD.MM.YYYY')})
      currentMoment.add(1, 'days');
    }
    setDateArray(items)
  }

  const getStoreTimeValues = (value, cart) => {
    let newArray = []
    try { newArray = selectedLocationData.opening_hours.periods }
    catch {}
    var items = []
    const currentMoment = moment(value, 'YYYY-MM-DD')
    let timeData = customArrayFilter(newArray, 'day', currentMoment.day())
    let start = moment(timeData['open'].time, 'HHmm')
    let end = moment(timeData['close'].time, 'HHmm')
    if(1===1)
      start = start.add(30, 'minutes')
    if(cart.pickUpFrom) {
      let pickUpFrom = moment(cart.pickUpFrom, 'HHmm')
      if(pickUpFrom > start)
        start = pickUpFrom
    }
    if(cart.pickUpUntill) {
      let pickUpUntill = moment(cart.pickUpUntill, 'HHmm')
      if(pickUpUntill < end)
        end = pickUpUntill
    }
    while ( start < end ) {
      items.push({id: start.format('HH:mm'), name: start.format('HH:mm')})
      start = start.add(30, 'minutes')
    }
    setTimeArray(items)
  }

  return(
    <section key={`#checkout-data`} id={`#checkout-data`}>
      <Container>
        <Grid divided stackable columns={2}>
          <Grid.Column>
            <Header className={'headline-regular'} as='h1'>{t(`haedline-cart`)}</Header>
            {cart && cart.items.length > 0 ? <Item.Group divided>
              {cart.items.map((e,index)=>(
                renderCartItems(index, e, handleOnItemPlus, handleOnItemMinus)
              ))}
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span>Zwischensumme</span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span>{cart.priceTotal} €</span> 
                </div>
              </div>
              <Divider/>
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span>zzgl. Steuern</span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span>{cart.taxTotal} €</span> 
                </div>
              </div>
              <Divider/>
              <div style={{ display: 'flex', flexDirection: 'row', margin: '20px'}}>
                <div style={{textAlign: 'left', flex: '1 1 0px'}}>
                  <span><strong>Gesamtsumme</strong></span>  
                </div>
                <div style={{textAlign: 'right', flex: '1 1 0px'}}>
                  <span><strong>{cart.total} €</strong></span> 
                </div>
              </div>
            </Item.Group> :
            <Message size='huge' >
              <Message.Header>{t(`headline-cart-empty`)}</Message.Header>
              <p>{t(`content-cart-empty`)}</p>
            </Message>
            }
          </Grid.Column>
          {!send ? <Grid.Column>
            <Header className={'headline-regular'} as='h1'>{t(`haedline-billing`)}</Header>
            <Form unstackable loading={loading}>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.givenname}
                  valueError={data.givennameError}
                  id="givenname"
                  type="text"
                  label={t('input-label-givenname')}
                  required
                />
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.surname}
                  valueError={data.surnameError}
                  id="surname"
                  type="text"
                  label={t('input-label-surname')}
                  required
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.mail}
                  valueError={data.mailError}
                  id="mail"
                  type="mail"
                  label={t('input-label-mail')}
                  required
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.phone}
                  valueError={data.phoneError}
                  id="phone"
                  type="text"
                  label={t('input-label-phone')}
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnStoreChange}
                  value={data.pickupStore}
                  valueError={data.pickupStoreError}
                  id="store"
                  type="select"
                  label={t('input-label-store')}
                  options={locations}
                  required
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnDateChange}
                  value={data.pickupDate}
                  valueError={data.pickupDateError}
                  id="pickup_date"
                  type="select"
                  options={dateArray}
                  label={t('input-label-pickup_date')}
                  required
                />
                <InputField 
                  handleOnChange={handleOnTimeChange}
                  value={data.pickupTime}
                  valueError={data.pickupTimeError}
                  id="pickup_time"
                  type="select"
                  options={timeArray}
                  label={t('input-label-pickup_time')}
                  required
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChange}
                  value={data.notes}
                  id="notes"
                  type="textarea"
                  label={t('input-label-options')}
                  rows={8}
                />
              </Form.Group>
              <Image
                src={payPalLogo}
                size='small'
              />
              <div style={{textAlign: 'left', flex: '1 1 0px', marginBottom: '20px'}}>
                <span>{t('content-cart-paypal')}</span>  
              </div>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChangeCheckbox}
                  id="iAgree"
                  type="checkbox"
                  label={data.iAgreeError ? <label style={{color: '#7F1019'}}>{t('input-label-policy')}</label> : <label>{t('input-label-policy')}</label>}
                  required
                />
              </Form.Group>
              <Form.Group>
                <InputField 
                  handleOnChange={handleOnChangeConditions}
                  id="conditions"
                  type="checkbox"
                  label={data.conditionsError ? <label style={{color: '#7F1019'}}>{t('input-label-conditions')}</label> : <label>{t('input-label-conditions')}</label>}
                  required
                />
              </Form.Group>
              <Form.Group>
                {cart && <DrawButton
                  name={t(`button-order`)}
                  onClick={handleOnSubmit}
                />}
              </Form.Group>
            </Form>
          </Grid.Column> : 
          <Grid.Column>
            <Message size='huge' positive>
              <Message.Header>{t(`headline-checkout-success`)}</Message.Header>
              <p>{t(`content-checkout-success`)}</p>
            </Message>
          </Grid.Column>
          }
        </Grid>
      </Container>
    </section>
  );
}

export default Checkout;

function renderCartItems(index, e, handleOnItemPlus, handleOnItemMinus) {
  return <CartItem
    key={`cart-item-#${index}`}
    productId={e.productId}
    name={e.name}
    description={e.description}
    notes={e.notes}
    price={e.price}
    currency={e.currency}
    qty={e.qty}
    total={e.total}
    isAvailable={e.isAvailable}
    onPlus={handleOnItemPlus}
    onMinus={handleOnItemMinus} />
}
