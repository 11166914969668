export const NETWORK_ERROR             = "NETWORK_ERROR"
export const NETWORK_START             = "NETWORK_START"
export const NETWORK_STOP              = "NETWORK_STOP"
export const LOADING_START             = "LOADING_START"
export const LOADING_STOP              = "LOADING_STOP"
export const TOGGLE_SIDEBAR            = "TOGGLE_SIDEBAR"
export const TOGGLE_MENUCARD           = "TOGGLE_MENUCARD"
export const STORES_LOAD               = "STORES_LOAD"
export const STORES_SETME              = "STORES_SETME"
export const CATEGORIES_SET            = "CATEGORIES_SET"
export const PRODUCTS_SET              = "PRODUCTS_SET"
export const CART_LOAD                 = "CART_LOAD"
export const CART_ADD                  = "CART_ADD"
export const CART_REMOVE               = "CART_REMOVE"
export const CART_CLEAR                = "CART_CLEAR"
export const NOTIFICATION_ADD          = "NOTIFICATION_ADD"
export const NOTIFICATION_REMOVE       = "NOTIFICATION_REMOVE"