import React from 'react';
import { useLocation } from 'react-router-dom';
import { Loader, Header, Hero, Footer, WhatsApp, ScrollHandler } from '../components';
import Notification from '../components/notification';
import config from '../config/main.json';

function Layout({children}) {
  const location = useLocation()
  return(
    <React.Fragment>
      <Loader />
      <Notification />
      <WhatsApp
        phone={config.whatsApp}
      />
      <ScrollHandler 
        location={location}
      />
      <div id="main-panel">
        <Header />
        <Hero
          id="hero"
          color={'rgba(104, 76, 26, 0.85)'}
        />
        {children}
        <Footer
          id="footer" 
        />
      </div>
    </React.Fragment>
  )
}

export default Layout