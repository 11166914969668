import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }

const IconGridItem = (props) => {
  return(
    <Grid.Column>
      <div style={{padding: 30, textAlign: 'center'}}>
        <Image src={props.src} size='tiny' centered/>
        <p style={{padding: 10}}>{props.content}</p>
      </div>
    </Grid.Column>
  )
}

const IconGridItemReveal = (props) => {
  return(
    <Grid.Column>
      <div style={{padding: 30, textAlign: 'center'}}>
        <Image src={props.src} size='tiny' centered/>
        <p style={{padding: 10}}>{props.content}</p>
      </div>
    </Grid.Column>
  )
}

const IconGrid = (props) => {
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg|webp)$/));
  if(!props.icons)
    return null

  return(
    <Grid stackable container columns={props.columns ? props.columns : 4}>
      {props.icons.map((element, index) => {
        let img = element.image
        if(!props.reveal)
          return(<IconGridItem key={`icon-grid-item-#${index}`} src={images[img]} content={element.content} />)
        else
          return(<IconGridItemReveal key={`icon-grid-item-#${index}`} src={images[img]} content={element.content} />)
      })}
    </Grid>
  );
}

export default IconGrid;